import { Button, Box, TextField, Container, Typography } from "@mui/material";
import RecommendOutlinedIcon from '@mui/icons-material/RecommendOutlined';
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import TeamProfile from "../../hooks/useTeam";
import SnackBars from "../../components/CustomizedSnackBars";
import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from "jwt-decode";
import { tokens, useMode } from "../../theme";
import LoginProfile from "../../hooks/useLoginUser";
import { Formik } from "formik";
import useMediaQuery from "@mui/material/useMediaQuery";
import * as yup from "yup";


const FormCadLogin = () => {
  const [theme, colorMode] = useMode();
  const colors = tokens(theme.palette.mode);
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [account, setAccount] = useState([]);
  const [resp, setResp] = useState(null);
  const [emailError, setEmailError] = useState(null);

  const { token } = useParams();
  const { sub } = jwtDecode(token);
  const { account_uuid, account_id, profile_id, email } = sub;

  const navigate = useNavigate();
  const api = process.env.REACT_APP_API_URL;

  initialValues.company = `${account.company ? account.company : ""}`;
  initialValues.email = `${email ? email : ""}`;

  const isDark = () => {
    if(theme.palette.mode === "dark"){
      return "secondary";
    }
    else {
      return "primary";
    }
  };

  const fetchData = async (validaemail) => {
    const url = `${api}/login/validalogin/${validaemail}`;
    try {
      const resp = await fetch(url);
      if (resp.status === 401) {
        setEmailError("Email já cadastrado. Favor usar outro email");
      } else {
        setEmailError(null);
      }
    } catch (error) {
      console.error("Erro ao validar email:", error);
      setEmailError("Erro ao validar o email. Tente novamente mais tarde.");
    }
  };

  useEffect(() => {
    const fetchAccount = async () => {
      const [accountResponse] = await Promise.all([
        fetch(`${api}/accountuuid/${account_uuid}`).then((resp) => resp.json())
      ]);
      setAccount(accountResponse);
    }

    fetchAccount();
  },[]);

  //Handle de submit do form
  const handleFormSubmit = async (values) => {
    const url = `${api}/login/cadastro`;
    
    const login = {
      fullName: values.fullName,
      email: values.email,
      password: values.password,
      profile : "standard",
      status : "active",
      account_id: account.id,
      profile_id: profile_id
    };

    console.log('login', login);
    
    const request = await fetch(url,{
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "Access-Control-Allow-Methods": "POST, GET, PUT, DELETE",
        "Access-Control-Allow-Headers": "*",
        "Access-Control-Max-Age": "86400",
      },
      body: JSON.stringify(login),
    });
    setResp(request.status); 
    if(request.ok){
      navigate('/login');
    }
  };

  async function delay() {
    await new Promise(resolve => setTimeout(resolve, 2000)); // Tempo em milissegundos (3 segundos neste caso)
    navigate('/login');
  }


  const handleBlur = (e) => {
    console.log("handleBlur",e);
  }

  //seta o resp como null sempre que o form for alterado
  const handleFormChange = () => {
    setResp(null);
  };

  return (
    <Container component="main">
      <Box
          style={{
            display: "flex",
            flexDirection:"row",
            alignContent: "center",
            justifyContent: "center",
            gap:"10px",
            padding:"10px",
            marginTop: theme.spacing(8),
            //backgroundColor: colors.grey[400],
          }}
      >
        
        {/*BOX 1 */}
        <Box
          style={{
            width: "50%",
            display: "grid",
            alignItems: "center",
            //backgroundColor: colors.primary[400],
            padding: 30,
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
                <img
                alt="profile-user"
                width="100%"
                height="100%"
                src={`/assets/login_allmetrics.png`}
                //style={{ cursor: "pointer", borderRadius: "50%" }}
                />
          </Box>
          
        </Box>
        {/*BOX 2 */}
        <Box
          style={{
            width: "50%",
            display: "grid",
            alignItems: "flex-start",
            borderLeft: "solid 1px " + colors.greenAccent[500],
            borderColor: colors.greenAccent[500],
            padding: 20,
          }}
        >
          <Typography component="h1" variant="h5" style={{ color: colors.greenAccent[500] }}>
            Novo usuário
          </Typography>
          <Box
          >
            <Formik
              onSubmit={handleFormSubmit}
              onBlur={handleBlur}
              initialValues={initialValues}
              validationSchema={checkoutSchema}
            >
              {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
              }) => (
                <form onSubmit={handleSubmit} onChange={handleFormChange}>
                  <Box
                    display="grid"
                    gap="30px"
                    gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                    sx={{
                      "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                    }}
                  >
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="Name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.fullName}
                      name="fullName"
                      error={!!touched.fullName && !!errors.fullName}
                      helperText={touched.fullName && errors.fullName}
                      sx={{ gridColumn: "span 4" }}
                    />
                    <TextField
                      fullWidth
                      disabled={email ? true : false}
                      variant="filled"
                      type="text"
                      label="E-mail"
                      onBlur={(e)=>{
                        fetchData(values.email);
                      }}
                      onChange={handleChange}
                      value={values.email}
                      name="email"
                      error={!!(touched.email && errors.email) || !!emailError} // Considere o email como erro se houver um erro retornado pela API
                      helperText={touched.email && errors.email ? errors.email : emailError} // Exiba o erro da API se houver, caso contrário, exiba o erro de validação padrão
                      sx={{ gridColumn: "span 4" }}
                    />
                    <TextField
                      fullWidth
                      variant="filled"
                      type="password"
                      label="Senha"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.password}
                      name="password"
                      error={!!touched.password && !!errors.password}
                      helperText={touched.password && errors.password}
                      sx={{ gridColumn: "span 4" }}
                    />
                    <TextField
                      disabled
                      fullWidth
                      variant="filled"
                      type="text"
                      label="Company"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.company}
                      name="company"
                      error={!!touched.company && !!errors.company}
                      helperText={touched.company && errors.company}
                      sx={{ gridColumn: "span 4" }}
                    />
                  </Box>
                  <Box display="flex" justifyContent="end" mt="20px">
                  <SnackBars buttonName="Save New Login" buttonType="submit" resp={resp} />
                    {/*<Button type="submit" color="secondary" variant="contained">
                      PRÓXIMO
                    </Button>*/}
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
        </Box>
      </Box>
      
    </Container>
  );
};

const passwordRegExp = /^(?=.*[A-Z])(?=.*[!@#$%^&*()-+=])(?=.{8,})/;

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const checkoutSchema = yup.object().shape({
  fullName: yup.string().required("required"),
  email: yup.string().email("invalid email").required("required"),
  password: yup
    .string()
    .matches(passwordRegExp,
      `Senha não atende aos critérios de segurança.
       Deve ter no mínimo 8 caracteres.
       Deve conter uma letra maiúscula.
       Deve conter caracteres especiais: !@#$%^&*`
    )
    .required("required"),
  company: yup.string().required("required")
});
const initialValues = {
  fullName: "",
  email: "",
  password: "",
  company: "",
};

export default FormCadLogin;
