import { useTheme } from "@mui/material";
import { ResponsiveBar } from "@nivo/bar";
import { tokens } from "../theme";
//import { mockBarData as data } from "../data/mockData";

const mockData = [
  {
    "people_target": "Operational",
    "Operational": 4.333333333333333,
    "initial_date": "01/04/2024"
  },
  {
    "people_target": "Strategic",
    "Strategic": 3.75,
    "initial_date": "01/07/2024"
  },
  {
    "people_target": "Tactical",
    "Tactical": 2.6666666666666665,
    "initial_date": "01/07/2023"
  },
];

const mockKeys = [
  "Operational",
  "Strategic",
  "Tactical"
];

const BarChart = ({ isDashboard = false, data, keys, indexBy, groupMode, childToParent, interactive, color, maxValue, tickRotation }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <ResponsiveBar
      data={ data ? data : mockData }
      theme={{
        // added
        axis: {
          domain: {
            line: {
              stroke: colors.grey[100],
            },
          },
          legend: {
            text: {
              fill: colors.grey[100],
            },
          },
          ticks: {
            line: {
              stroke: colors.grey[100],
              strokeWidth: 1,
            },
            text: {
              fill: colors.grey[100],
            },
          },
        },
        legends: {
          text: {
            fill: colors.grey[100],
          },
        },
      }}
      keys={keys ? keys : mockKeys}
      indexBy={indexBy}
      margin={{ top: 50, right: 130, bottom: 50, left: 40 }}
      padding={0.3}
      minValue={0}
      maxValue={maxValue && maxValue}
      groupMode={groupMode}
      valueScale={{ type: "linear" }}
      indexScale={{ type: "band", round: true }}
      colors={color && color ? color : { scheme: "nivo" }} // verificar se foi passada alguma cor via props, caso negativo, assume o schema padrão "nivo"
      defs={[
        {
          id: "dots",
          type: "patternDots",
          background: "inherit",
          color: "#38bcb2",
          size: 4,
          padding: 1,
          stagger: true,
        },
        {
          id: "lines",
          type: "patternLines",
          background: "inherit",
          color: "#eed312",
          rotation: -45,
          lineWidth: 6,
          spacing: 10,
        },
      ]}
      borderColor={{
        from: "color",
        modifiers: [["darker", "1.6"]],
      }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: tickRotation ? tickRotation : 0,
        legend: isDashboard ? undefined : {indexBy}, // changed
        legendPosition: "middle",
        legendOffset: 32,
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: isDashboard ? undefined : "score", // changed
        legendPosition: "middle",
        legendOffset: -40,
      }}
      enableLabel={true}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{
        from: "color",
        modifiers: [["darker", 3]],
      }}
      legends={[
        {
          dataFrom: "keys",
          anchor: "bottom-right",
          direction: "column",
          justify: false,
          translateX: 110,
          translateY: 0,
          itemsSpacing: 2,
          itemWidth: 100,
          itemHeight: 20,
          itemDirection: "left-to-right",
          itemOpacity: 0.85,
          symbolSize: 20,
          effects: [
            {
              on: "hover",
              style: {
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
      isInteractive={interactive ? true : false}
      isFocusable={interactive ? true : false}
      onClick={function (data, nameBoard) {
        childToParent(data, nameBoard);
      }}
      role="application"
      barAriaLabel={function (e) {
        return e.id + ": " + e.formattedValue + " in country: " + e.indexValue;

      }}
    />
  );
};

export default BarChart;
