import { useState } from "react";
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Rating from '@mui/material/Rating';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material';
import React from 'react';
import { tokens } from "../theme";

const StyledRating = styled(Rating)({
  '& .MuiRating-iconFilled': {
    color: '#ff6d75',
  },
  '& .MuiRating-iconHover': {
    color: '#ff3d47',
  },
});

export default function CustomizedRating({defaultValue, precision, size, title, name,
   childToParent, gridColumn, classification}) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box
      sx={{
        '& > legend': { mt: 0.5 },
        gridColumn: {gridColumn},
        border: "2px solid rgba(252, 249, 249, 0.1)",
        borderRadius: "10px",
        marginBottom: "0.8rem",
        padding: "0.5rem",
      }}
    >
      <Typography 
        component="legend"
        variant='filled'
        sx={{marginBottom: "0.5rem",
            color: colors.greenAccent[300]}}
      >
          {title}
      </Typography>
      <StyledRating
        name={name}
        classification = {classification}
        defaultValue={defaultValue}
        getLabelText={(value) => `${value} Heart${value !== 1 ? 's' : ''}`}
        onChange={(value) => {childToParent(value.target.defaultValue, name, classification)}}
        precision={precision}
        icon={<FavoriteIcon fontSize="inherit" />}
        emptyIcon={<FavoriteBorderIcon fontSize="inherit" />}
        size={size}
        sx={{
            transition: "all 0.3s",
            display: "flex"
        }}
      />
    </Box>
  );
}