import { Box, Button, IconButton, Typography, useTheme, Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import { tokens } from "../../theme";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import Header from "../../components/Header";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import TeamProfile from "../../hooks/useTeam";
import MyResponsiveHeatMap from "../../components/HeatMap";
import { Formik } from "formik";
import useMediaQuery from "@mui/material/useMediaQuery";
import '../../utils/i18n';
import { useTranslation } from 'react-i18next';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import DrawerFilterSatisfaction from "../../components/DrawerFilterSatisfaction";
import LoginProfile from "../../hooks/useLoginUser";

const mockHeatMap = [
  {
    "id": null,
    "data": [
      {
        "x": null,
        "y": null
      },
    ]
  },
];


const HeatMapDashboard = () => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [heatMap, setHeatMap] = useState([]);
  const [option, setOption] = useState(0);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [filter, setFilter] = useState({
    selectTeam: 0,
    people_target: 0,
    isFilter: false,
  });

  const api = process.env.REACT_APP_API_URL;

  const isNonMobile = useMediaQuery("(min-width:600px)");

  const location = useLocation();

  initialValues.country = 1;

  const fetchData = async () => {
    const urlHeatMap = [`${api}/happinessradar/${LoginProfile.getUserId()}/heatmap/${filter.selectTeam}/people_target/${filter.people_target}`,`
      ${api}/happinessradar/${LoginProfile.getUserId()}/heatmapitem/${filter.selectTeam}/people_target/${filter.people_target}`];
    const options = {
      method: 'GET',
      headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Methods": "POST, GET, PUT, DELETE",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Max-Age": "86400",
          "Authorization": `${sessionStorage.getItem('token')}`
      }
    };
    const resp = await fetch(urlHeatMap[option], options);
    const json = await resp.json();
    setHeatMap(json);
  };

  useEffect(() => {
    fetchData();
  }, [filter]);

  const handleChange = (event) => {
    setOption(event.target.value);
  }

  const handleDrawerToggle = () => {
    setOpenDrawer(!openDrawer);
  };

  const handleFiltersChange = (selectFilters) => {
    setFilter(selectFilters);
    console.log("filtro aplicado", selectFilters);
  };


  return (
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title={t("Heatmap Dashboard")} subtitle={t("Welcome to heatmap dashboard")} />
        <Box>
        <Button
              onClick={handleDrawerToggle}
              sx={{
                backgroundColor: colors.blueAccent[700],
                color: filter.isFilter === true ? colors.greenAccent[400] : colors.grey[100],
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px",
              }}
            >
              {filter.isFilter === true ? <FilterAltOutlinedIcon sx={{ mr: "10px" }} /> : <TuneOutlinedIcon sx={{ mr: "10px" }} />}
              {t("Filter")}
            </Button>
        </Box>
      </Box>
      <DrawerFilterSatisfaction 
        open={openDrawer}
        onClose={handleDrawerToggle}
        onApplyFilters={handleFiltersChange}
        newFilter={
          <FormControl>
          <InputLabel>{t("Options")}</InputLabel>
            <Select
              fullWidth
              variant="filled"
              label="Country"
              value={option}
              onChange={handleChange}
              name="country"
              sx={{ gridColumn: "span 2" }}
              >
                <MenuItem value={0}>{t("Heatmap by Touch")}</MenuItem>
                <MenuItem value={1}>{t("Heatmap by Happiness Radar")}</MenuItem>
            </Select>
          </FormControl>
        }
      />
      {/* GRID & CHARTS */}
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
      >

        {/* ROW 1 */}
        <Box
          gridColumn="span 12"
          gridRow="span 4"
          backgroundColor={colors.primary[400]}
          borderRadius="10px"
        >
          <Box
            mt="25px"
            p="0 30px"
            display="flex "
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography
                variant="h5"
                fontWeight="600"
                color={colors.grey[100]}
              >
                {t("Heatmap")}
              </Typography>
              <Typography
                variant="h3"
                fontWeight="bold"
                color={colors.greenAccent[500]}
              >
              </Typography>
            </Box>
            <Box>
              <IconButton>
                <DownloadOutlinedIcon
                  sx={{ fontSize: "26px", color: colors.greenAccent[500] }}
                />
              </IconButton>
            </Box>
          </Box>
          <Box height="90%" padding="20px">
            {/*<LineChart isDashboard={false} data={lineChart ? lineChart : mocklineChart} keys={keysLineChart ? keysLineChart : mockKeyLineChart} maxValue={5}  />*/}
            {heatMap ? <MyResponsiveHeatMap data={heatMap ? heatMap : mockHeatMap} /> : null}
          </Box>
        </Box>
      {/* ROW 1 */}
      </Box>
    </Box>
  );
};

const initialValues = {
  country: "",
};

export default HeatMapDashboard;
