import * as React from 'react';
import { Link } from "react-router-dom";
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Typography from '@mui/material/Typography';
import '../utils/i18n';
import { useTranslation } from "react-i18next";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import TouchAppOutlinedIcon from '@mui/icons-material/TouchAppOutlined';
import AutoModeOutlinedIcon from '@mui/icons-material/AutoModeOutlined';

export default function CustomizedTimeline() {
    const { t, i18n } = useTranslation();
  return (
    <Timeline position="alternate">
      <TimelineItem>
        <TimelineOppositeContent
          sx={{ m: 'auto 0' }}
          align="right"
          variant="body3"
          color="text.secondary"
        >
          {t("1º Step")}
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector />
            <a href="https://rodrigopessanhati.nimbusweb.me/share/10618403/dkh287l8izxyxth9hcyq" target="_blank" rel="noopener noreferrer" >
              <TimelineDot >
                  <PeopleOutlinedIcon sx={{ fontSize: '36px' }} />
              </TimelineDot>
            </a>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: '34px', px: 2 }}>
          <Typography variant="h3" component="span">
            {t("Team")}
          </Typography>
          <Typography variant="h4">{t("Create a team to get started")}</Typography>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineOppositeContent
          sx={{ m: 'auto 0' }}
          variant="body5"
          color="text.secondary"
        >
          {t("2º Step")}
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector />
          <a href="https://rodrigopessanhati.nimbusweb.me/share/10619487/uvw6bdz8698295e6p4sw" target="_blank" rel="noopener noreferrer" >
            <TimelineDot>
              <ManageAccountsOutlinedIcon sx={{ fontSize: '36px' }} />
            </TimelineDot>
          </a>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: '34px', px: 2 }}>
          <Typography variant="h3" component="span">
            {t("Team User")}
          </Typography>
          <Typography variant="h4">{t("Add team to the team")}</Typography>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineOppositeContent
        sx={{ m: 'auto 0' }}
          variant="body5"
          color="text.secondary"
        >
          {t("3º Step")}
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector />
          <a href="https://rodrigopessanhati.nimbusweb.me/share/10619489/mx5156bv1d9omfp0lw8e" target="_blank" rel="noopener noreferrer" >
            <TimelineDot >
              <TouchAppOutlinedIcon sx={{ fontSize: '36px' }} />
            </TimelineDot>
          </a>
          <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />
        </TimelineSeparator>
        <TimelineContent sx={{ py: '34px', px: 2 }}>
          <Typography variant="h3" component="span">
            {t("Touch Campaign")}
          </Typography>
          <Typography variant="h4" >{t("Create touch campaigns according to the cadence in which the surveys will occur")}</Typography>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
      <TimelineOppositeContent
        sx={{ m: 'auto 0' }}
          variant="body5"
          color="text.secondary"
        >
          {t("4º Step")}
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />
          <a href="https://rodrigopessanhati.nimbusweb.me/share/10619492/nucq2qckh27enkkkxodi" target="_blank" rel="noopener noreferrer" >
            <TimelineDot color="secondary">
              <AutoModeOutlinedIcon sx={{ fontSize: '36px' }} />
            </TimelineDot>
          </a>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: '34px', px: 2 }}>
          <Typography variant="h3" component="span">
            {t("Send")}
          </Typography>
          <Typography variant="h4" >{t("Send touch survey and evaluate results on dashboards")}</Typography>
        </TimelineContent>
      </TimelineItem>
    </Timeline>
  );
}