export const mockPaises = [ 
    {
        gentilico : "Selecione",
        nome_pais : "Selecione",
        nome_pais_int : "Não se Aplica",
        sigla : "N/A"
    },
    {
        gentilico : "alemã",
        nome_pais : "Alemanha",
        nome_pais_int : "Germany",
        sigla : "DE"
    },
    {
        gentilico : "argentina",
        nome_pais : "Argentina",
        nome_pais_int : "Argentina",
        sigla : "AR"
    },
    {
        gentilico : "australiana",
        nome_pais : "Austrália",
        nome_pais_int : "Australia",
        sigla : "AU"
    },
    {
        gentilico : "austríaca",
        nome_pais : "Áustria",
        nome_pais_int : "Austria",
        sigla : "AT"
    },
    {
        gentilico : "bielo-russa",
        nome_pais : "Belarus",
        nome_pais_int : "Belarus",
        sigla : "BY"
    },
    { 
        gentilico : "belga",
        nome_pais : "Bélgica",
        nome_pais_int : "Belgium",
        sigla : "BE"
    },
    {
        gentilico : "boliviana",
        nome_pais : "Bolívia",
        nome_pais_int : "Bolivia",
        sigla : "BO"
    },
    {
        gentilico : "brasileira",
        nome_pais : "Brasil",
        nome_pais_int : "Brazil",
        sigla : "BR"
    },
    {
        gentilico : "canadense",
        nome_pais : "Canadá",
        nome_pais_int : "Canada",
        sigla : "CA"
    },
    {
        gentilico : "chilena",
        nome_pais : "Chile",
        nome_pais_int : "Chile",
        sigla : "CL"
    },
    {
        gentilico : "chinesa",
        nome_pais : "China",
        nome_pais_int : "China",
        sigla : "CN"
    },
    {
        gentilico : "colombiana",
        nome_pais : "Colômbia",
        nome_pais_int : "Colombia",
        sigla : "CO"
    },
       {
        gentilico : "sul-coreana",
        nome_pais : "Coréia do Sul",
        nome_pais_int : "South Korea",
        sigla : "KR"
    },
    {
        gentilico : "equatoriana",
        nome_pais : "Equador",
        nome_pais_int : "Ecuador",
        sigla : "EC"
    },
    {
        gentilico : "espanhola",
        nome_pais : "Espanha",
        nome_pais_int : "Spain",
        sigla : "ES"
    },
    {
        gentilico : "norte-americana",
        nome_pais : "Estados Unidos",
        nome_pais_int : "United States",
        sigla : "US"
    },
    { 
        gentilico : "estoniana",
        nome_pais : "Estônia",
        nome_pais_int : "Estonia",
        sigla : "EE"
    },
    { 
        gentilico : "francesa",
        nome_pais : "França",
        nome_pais_int : "France",
        sigla : "FR"
    },
    {
        gentilico : "inglesa",
        nome_pais : "Grã-Bretanha (Reino Unido, UK)",
        nome_pais_int : "United Kingdom",
        sigla : "GB"
    },
    { 
        gentilico : "holandês",
        nome_pais : "Holanda",
        nome_pais_int : "Netherlands",
        sigla : "NL"
    },
    { 
        gentilico : "irlandesa",
        nome_pais : "Irlanda",
        nome_pais_int : "Ireland",
        sigla : "IE"
    },
    {
        gentilico : "islandesa",
        nome_pais : "Islândia",
        nome_pais_int : "Iceland",
        sigla : "IS"
    },
    {
        gentilico : "israelense",
        nome_pais : "Israel",
        nome_pais_int : "Israel",
        sigla : "IL"
    },
    { 
        gentilico : "italiana",
        nome_pais : "Itália",
        nome_pais_int : "Italy",
        sigla : "IT"
    },
    { 
        gentilico : "jamaicana",
        nome_pais : "Jamaica",
        nome_pais_int : "Jamaica",
        sigla : "JM"
    },
    { 
        gentilico : "japonesa",
        nome_pais : "Japão", 
        nome_pais_int : "Japan",
        sigla : "JP"
    },
    { 
        gentilico : "mexicana",
        nome_pais : "México",
        nome_pais_int : "Mexico",
        sigla : "MX"
    },
    {
        gentilico : "neozelandesa",
        nome_pais : "Nova Zelândia",
        nome_pais_int : "New Zealand",
        sigla : "NZ"
    },
    { 
        gentilico : "paraguaia",
        nome_pais : "Paraguai",
        nome_pais_int : "Paraguay",
        sigla : "PY"
    },
    { 
        gentilico : "peruana",
        nome_pais : "Peru",
        nome_pais_int : "Peru",
        sigla : "PE"
    },
    {
        gentilico : "polonesa",
        nome_pais : "Polônia",
        nome_pais_int : "Poland",
        sigla : "PL"
    },
    { 
        gentilico : "portorriquenha",
        nome_pais : "Porto Rico",
        nome_pais_int : "Puerto Rico",
        sigla : "PR"
    },
    { 
        gentilico : "portuguesa",
        nome_pais : "Portugal",
        nome_pais_int : "Portugal",
        sigla : "PT"
    },
    { 
        gentilico : "uruguaia",
        nome_pais : "Uruguai",
        nome_pais_int : "Uruguay",
        sigla : "UY"
    },
    { 
        gentilico : "venezuelana",
        nome_pais : "Venezuela",
        nome_pais_int : "Venezuela",
        sigla : "VE"
    },
];