import { Box, Button, TextField, Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import { mockPaises } from '../../data/mockPaises copy'
import SnackBars from "../../components/CustomizedSnackBars";
import { useState } from "react";
import { useParams } from "react-router-dom";
import {useFetch} from '../../hooks/useFetch';


const Form = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const[method,setMethod] = useState(null);
  //recebe id do account para carregar o form para edição
  const { id } = useParams();
  //----------------------------------------------------
  
  const urlGetAccount = `http://127.0.0.1:5000/account/${id}`;
  const { data } = useFetch(urlGetAccount);
  

  initialValues.firstName = `${data.firstName ? data.firstName : ""}`;
  initialValues.lastName = `${data.lastName ? data.lastName : ""}`;
  initialValues.email = `${data.email ? data.email : ""}`;
  initialValues.phone = `${data.phone ? data.phone : ""}`;
  initialValues.phoneCell = `${data.phoneCell ? data.phone : ""}`;
  initialValues.address = `${data.address ? data.address : ""}`;
  initialValues.address2 = `${data.address2 ? data.address2 : ""}`;
  initialValues.city = `${data.city ? data.city : ""}`;
  initialValues.country = `${data.country ? data.country : ""}`;

  //seta url para cadastro (POST) e grava o retorno da API no Resp
  const url = "http://127.0.0.1:5000/account/cadastro";
  const [resp, setResp] = useState(null);
  //-------------------------------------------------------------

  //seta o resp como null sempre que o form for alterado
  const handleFormChange = () => {
    setResp(null);
  };
  //--------------------------------------------------

  //Handle de submit do form
  const handleFormSubmit = async (values) => {

    const account = {
      account_id : id,
      firstName : values.firstName,
      lastName : values.lastName,
      email : values.email,
      phone : values.phone,
      phoneCell : values.phoneCell,
      address : values.address,
      address2 : values.address2,
      city : values.city,
      country : values.country,
    };

    const request = await fetch(url,{
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "Access-Control-Allow-Methods": "POST, GET, PUT, DELETE",
        "Access-Control-Allow-Headers": "*",
        "Access-Control-Max-Age": "86400",
      },
      body: JSON.stringify(account),
    });

    setResp(request.status);
    
  };
  //------------------------------------------------

  return (
    <Box m="20px">
      <Header title="CREATE USER" subtitle="Create a New User Profile" />

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit} onChange={handleFormChange}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="First Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.firstName}
                name="firstName"
                error={!!touched.firstName && !!errors.firstName}
                helperText={touched.firstName && errors.firstName}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Last Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.lastName}
                name="lastName"
                error={!!touched.lastName && !!errors.lastName}
                helperText={touched.lastName && errors.lastName}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Email"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.email}
                name="email"
                error={!!touched.email && !!errors.email}
                helperText={touched.email && errors.email}
                sx={{ gridColumn: "span 4" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Contact Number"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.phone}
                name="phone"
                error={!!touched.phone && !!errors.phone}
                helperText={touched.phone && errors.phone}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Contact Cell Phone"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.phoneCell}
                name="phoneCell"
                error={!!touched.phoneCell && !!errors.phoneCell}
                helperText={touched.phoneCell && errors.phoneCell}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Address"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.address}
                name="address"
                error={!!touched.address && !!errors.address}
                helperText={touched.address && errors.address}
                sx={{ gridColumn: "span 4" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Address 2"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.address2}
                name="address2"
                error={!!touched.address2 && !!errors.address2}
                helperText={touched.address2 && errors.address2}
                sx={{ gridColumn: "span 4" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="City"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.city}
                name="city"
                error={!!touched.city && !!errors.city}
                helperText={touched.city && errors.city}
                sx={{ gridColumn: "span 2" }}
              />
              <FormControl fullWidth sx={{ gridColumn: "span 2" }}>
                <InputLabel>Country</InputLabel>
                <Select
                  fullWidth
                  variant="filled"
                  label="Country"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.country}
                  name="country"
                  error={!!touched.country && !!errors.country}
                  helperText={touched.country && errors.country}
                  sx={{ gridColumn: "span 2" }}
                  >
                    {mockPaises.map((pais)=>(
                      <MenuItem value={pais.sigla}>{pais.nome_pais}</MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Box>
            <SnackBars buttonName="Create New User" buttonType="submit" resp={resp} />
          </form>
        )}
      </Formik>
    </Box>
  );
};

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const checkoutSchema = yup.object().shape({
  firstName: yup.string().required("required"),
  lastName: yup.string().required("required"),
  email: yup.string().email("invalid email").required("required"),
  phone: yup
    .string()
    .matches(phoneRegExp, "Phone number is not valid")
    .required("required"),
  address: yup.string().required("required"),
  city: yup.string().required("required"),
  country: yup.string().required("required"),
});
const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  phoneCell: "",
  address: "",
  address2: "",
  city: "",
  country: "",
};

export default Form;