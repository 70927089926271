import { Box, Button, useTheme } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import {useState} from 'react';
import { useEffect } from "react";
import FAB from "../../components/FAB";
import EditIcon from "@mui/icons-material/Edit";
import Delete from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import LoginProfile from "../../hooks/useLoginUser";
import '../../utils/i18n';
import { useTranslation } from "react-i18next";
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';

const ManageLogin = () => {
  const { t, i18n } = useTranslation();
  const [team,setTeam] = useState([]);
  const [list, setList] = useState([]);

  const api = process.env.REACT_APP_API_URL;

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const columns = [
    {field: "id", headerName: "ID"},
    {
      field: "fullName",
      headerName: "Name",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "email",
      headerName: "Login",
      flex: 1,
    },
    {
      field: "profile",
      headerName: "Profile",
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
    },
  ];
 
  const fetchData = async () => {
    const url = `${api}/login/alllogin/${LoginProfile.getAccountId()}`;
    const options = {
      method: 'GET',
      headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Methods": "POST, GET, PUT, DELETE",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Max-Age": "86400",
          "Authorization": `${sessionStorage.getItem('token')}`
        }
    };
    const resp = await fetch(url, options);
    const json = await resp.json();
    setTeam(json);
};
  
  useEffect(() => {
    fetchData();
  },[]);

  const handleDelete = async () => {
    if(list.length > 1){
      list.forEach(async id => {
        const url = `${api}/login/id/${id}`;
        const request = await fetch(url, {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "Access-Control-Allow-Methods": "POST, GET, PUT, DELETE",
            "Access-Control-Allow-Headers": "*",
            "Access-Control-Max-Age": "86400",
            "Authorization" : `${sessionStorage.getItem('token')}`
          },
        });
        localStorage.setItem('alter_teams',Date.now());
        fetchData();
      });
    }
    else{
      const url = `${api}/login/id/${list}`;
      const request = await fetch(url, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Methods": "POST, GET, PUT, DELETE",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Max-Age": "86400",
          "Authorization" : `${sessionStorage.getItem('token')}`
        },
      });
      localStorage.setItem('alter_teams',Date.now());
      fetchData();
    }
  }
  
  return (
    <Box m="20px">
      <Header title={t("Manage Login")} subtitle={t("List of Logins")} />
      <Box
        m="40px 0 0 0"
        height="69vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-row": {
            borderBottom: "solid 1px",
            borderBottomColor: colors.grey[600],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <Box  display="flex" mr="10px" justifyContent="end" gap="8px" >
        <FAB
            to={`/invite`}
            icon={<MailOutlinedIcon />}
            title={t("Send Invite Login")}
          />
          <FAB
            to={`/managelogin/userLogin/${list[0]}`}
            icon={<EditIcon />}
            disabled={list.length === 1 ? false : true}
            title={t("Edit Login")}
          />
          <FAB
            to={`/managelogin`}
            icon={<Delete />}
            disabled={list.length >= 1 ? false : true}
            id={list}
            handleDelete={handleDelete}
            name="delete"
            title={t("Delete")}
          />
        </Box>
        <DataGrid 
          checkboxSelection
          rows={team}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
          onSelectionModelChange={setList}
      />
      </Box>
    </Box>
  );
};

export default ManageLogin;
