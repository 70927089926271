import { Box, TextField,Select,FormControl,InputLabel,MenuItem } from "@mui/material";
import { Formik, Field } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../../components/Header";
import SnackBars from "../../../components/CustomizedSnackBars";
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {useFetch} from '../../../hooks/useFetch';
import TeamProfile from "../../../hooks/useTeam";
import LoginProfile from "../../../hooks/useLoginUser";
import '../../../utils/i18n';
import { useTranslation } from "react-i18next";
import InputMask from "react-input-mask";
import TextEditor from "../../../components/TextEditor";
import { parse, format, isValid } from 'date-fns';


const FormCadastroFeedback = () => {
  const { t, i18n } = useTranslation();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [resp, setResp] = useState(null);
  const [employer, setEmployer] = useState([]);
  const [data, setData] = useState([]);
  const [value, setValue] = useState(null);

  const api = process.env.REACT_APP_API_URL;

  const navigate = useNavigate();

  //recebe id do account para carregar o form para edição
  const { id } = useParams();
  //----------------------------------------------------

  const fetchEmployer = async () => {
    const url = `${api}/user/team/${LoginProfile.getUserId()}`;
    const options = {
      method: 'GET',
      headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Methods": "POST, GET, PUT, DELETE",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Max-Age": "86400",
          "Authorization": `${sessionStorage.getItem('token')}`
        }
    };
    const resp = await fetch(url, options);
    const json = await resp.json();
    setEmployer(json);
  };

  const fechFeedback = async () => {
    const url = `${api}/feedback/${id}`;
    const options = {
      method: 'GET',
      headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Methods": "POST, GET, PUT, DELETE",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Max-Age": "86400",
          "Authorization": `${sessionStorage.getItem('token')}`
        }
    };
    const resp = await fetch(url, options);
    const json = await resp.json(resp);  
    setData(json);
  };

  useEffect(()=> {
    if(id){
      fechFeedback();
    }
    fetchEmployer();
      },[id]);

  const handleLanguageDate = () => {
    switch(localStorage.getItem('language')){
      case 'br':
        return 'pt-BR';
      case 'us':
        return 'en-US';
      case 'es':
        return 'es-ES';
    }
  }

  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  };


  initialValues.objective = `${data.objective ? data.objective : ""}`;
  initialValues.title = `${data.title ? data.title : ""}`;
  initialValues.realization_date = `${data.realization_date ? new Date(data.realization_date).toLocaleDateString(handleLanguageDate(localStorage.getItem('language')),options).split(',')[0].trim() : ""}`;
  initialValues.initial_time = `${data.initial_time ? data.initial_time : ""}`;
  initialValues.end_time = `${data.end_time ? data.end_time : ""}`;
  initialValues.objective = `${data.objective ? data.objective : ""}`;
  initialValues.employer = `${data.user_id ? data.user_id : ""}`;
  initialValues.anotation = `${data.anotation ? data.anotation : ""}`;

  //seta o resp como null sempre que o form for alterado
  const handleFormChange = () => {
    setResp(null);
  };

  const handleDataHora = (dataHora) => {
    let parsedDate = parse(dataHora, 'dd/MM/yyyy', new Date());
    
    // Se a primeira tentativa falhar, tenta o formato 'MM/dd/yyyy'
    if (!isValid(parsedDate)) {
        parsedDate = parse(dataHora, 'MM/dd/yyyy', new Date());
    }
    
    // Verifica se a data final é válida
    if (!isValid(parsedDate)) {
        console.error('Formato de data inválido');
        return null;
    }
    
    let dataFormatada = format(parsedDate, 'yyyy-MM-dd');
    return dataFormatada;
};

  //--------------------------------------------------

  //Handle de submit do form
  const handleFormSubmit = async (values) => {
    const url = `${api}/feedback/cadastro`;

    const feedback = {
      feedback_id : id,
      objective : values.objective,
      title : values.title,
      status: "scheduled",
      realization_date: handleDataHora(values.realization_date),
      initial_time : values.initial_time,
      end_time : values.end_time,
      user_id : values.employer,
      leader_id: LoginProfile.getUserId(),
      anotation: values.anotation
    };
    const request = await fetch(url,{
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "Access-Control-Allow-Methods": "POST, GET, PUT, DELETE",
        "Access-Control-Allow-Headers": "*",
        "Access-Control-Max-Age": "86400",
        "Authorization": `${sessionStorage.getItem('token')}`
      },
      body: JSON.stringify(feedback),
    });
    const { feedback_id } = await request.json();
    setResp(request.status);
    if(request.ok){
      if(id){
        navigate(`/feedback`);
      }
      else{
        navigate(`/feedback/assessment/${feedback_id}`);
      }
    }
  };
  
  const handleLabel = () => {
    if(id){
      return "Update Feedback";
    }
    return "Feedback 1:1";
  };

  return (
    <Box m="20px">
      <Header title={t(handleLabel())} subtitle={t("Create or edit 1:1 Feedback")} />

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit} onChange={handleFormChange}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(12, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 12" },
              }}
            >
              <FormControl fullWidth sx={{ gridColumn: "span 6" }}>
                <InputLabel>{t("Employer")}</InputLabel>
                <Select
                  fullWidth
                  variant="filled"
                  label={t("Employer")}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.employer}
                  name="employer"
                  error={!!touched.employer && !!errors.employer}
                  helperText={touched.employer && errors.employer}
                  sx={{ gridColumn: "span 6" }}
                  >
                    {employer && employer.map((employ)=>(
                      <MenuItem value={employ.id}>{t(employ.name)}</MenuItem>  
                    ))}
                </Select>
              </FormControl>
              <FormControl fullWidth sx={{ gridColumn: "span 6" }}>
                <InputLabel>{t("Objective")}</InputLabel>
                <Select
                  fullWidth
                  variant="filled"
                  label={t("Objective")}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.objective}
                  name="objective"
                  error={!!touched.objective && !!errors.objective}
                  helperText={touched.objective && errors.objective}
                  sx={{ gridColumn: "span 6" }}
                  >
                    <MenuItem value={t("Career")}>{t("Career")}</MenuItem>
                    <MenuItem value={t("Coaching")}>{t("Coaching")}</MenuItem>
                    <MenuItem value={t("Development")}>{t("Development")}</MenuItem>
                    <MenuItem value={t("Feedback")}>{t("Feedback")}</MenuItem>
                </Select>
              </FormControl>
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label={t("Tema")}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.title}
                name="title"
                error={!!touched.title && !!errors.title}
                helperText={touched.title && errors.title}
                sx={{ gridColumn: "span 12" }}
              />
              <InputMask
                mask={'99/99/9999'}
                maskPlaceholder={null} // Isso remove o hint de máscara do campo
                onChange={handleChange}
                value={values.realization_date}
                name="realization_date"
                error={!!(touched.realization_date && errors.realization_date)} // Considere o email como erro se houver um erro retornado pela API
                helperText={touched.realization_date && errors.realization_date} 
                sx={{ gridColumn: "span 4" }}
              >
                {(inputProps) => <TextField {...inputProps} fullWidth variant="filled" type="text" label={t("Realization Date")} />}
              </InputMask>
              <InputMask
                mask={'99:99'}
                maskPlaceholder={null} // Isso remove o hint de máscara do campo
                onChange={handleChange}
                value={values.initial_time}
                name="initial_time"
                error={!!(touched.initial_time && errors.initial_time)} // Considere o email como erro se houver um erro retornado pela API
                helperText={touched.initial_time && errors.initial_time} 
                sx={{ gridColumn: "span 4" }}
              >
                {(inputProps) => <TextField {...inputProps} fullWidth variant="filled" type="text" label={t("Initial Time")} />}
              </InputMask>
              <InputMask
                mask={'99:99'}
                maskPlaceholder={null} // Isso remove o hint de máscara do campo
                onChange={handleChange}
                value={values.end_time}
                name="end_time"
                error={!!(touched.end_time && errors.end_time)} // Considere o email como erro se houver um erro retornado pela API
                helperText={touched.end_time && errors.end_time} 
                sx={{ gridColumn: "span 4" }}
              >
                {(inputProps) => <TextField {...inputProps} fullWidth variant="filled" type="text" label={t("End Time")} />}
              </InputMask>
              <Field
                name="anotation"
                component={TextEditor}
                sx={{ gridColumn: "span 12" }}
                minLines={12}
                maxLines={18}
              />
            </Box>
            <SnackBars buttonName={t(handleLabel().toUpperCase())} buttonType="submit" resp={resp} />
          </form>
        )}
      </Formik>
    </Box>
  );
};

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const checkoutSchema = yup.object().shape({
  employer: yup.string().required("required")
});
const initialValues = {
  employer: "",
  objective: "",
  anotation: "",
};

export default FormCadastroFeedback;