import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import br from './br.json';
import us from './us.json';
import es from './es.json';

i18n.use(initReactI18next).init({
    compatibilityJSON: 'v3',
    lng: localStorage.getItem('language') ? localStorage.getItem('language') : 'br',
    resources:{
        us: us,
        br: br,
        es: es
    },
    react:{
        useSuspense: false,
    },
    interpolation:{
        escapeValue: false,
    }
});

export default i18n;