import { Fab, Tooltip } from "@mui/material";
import { Link }  from "react-router-dom";
import { useTheme } from '@emotion/react';
import { tokens } from '../theme';


const FAB = ({to,icon,disabled,handleDelete,handleSendTouch,handleActivity,name,title}) => {
  
  const handleFabFunction = () => { 
    switch(name){
      case "delete":
        handleDelete();
        break;
      case "touch":
        handleSendTouch();
        break;
      case "activity":
        handleActivity();
        break;
      default:
         break;
    }

    
  };
  
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  
  return (
    <Link to={to} className={disabled && "disabled-link"}>
      <Tooltip title={title}>
      <Fab 
        m="10px"
        type="submit"
        color="secondary"
        variant="contained"
        size="small"
        disabled = {disabled}
        onClick={handleFabFunction}
      >
        {icon}
      </Fab>
      </Tooltip>
    </Link>
  );
};

export default FAB