import { Button, Box, TextField, Container, Typography, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { tokens, useMode } from "../../theme";
import { Formik } from "formik";
import useMediaQuery from "@mui/material/useMediaQuery";
import * as yup from "yup";
import SnackBars from "../../components/CustomizedSnackBars";
import InputMask from "react-input-mask";
import { cnpj } from 'cpf-cnpj-validator';
import MultipleSelectCheckmarks from "../../components/MultipleSelectCheckMarks";


const FormCadAccount = () => {
  const [theme, colorMode] = useMode();
  const colors = tokens(theme.palette.mode);
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [data, setData] = useState([]);
  const [resp, setResp] = useState(null);
  const [dataType, setDataType] = useState();
  const [emailError, setEmailError] = useState(null);
  const [cnpjError, setCnpjError] = useState(null);
  const [phoneError, setPhoneError] = useState(null);
  const [product, setProduct] = useState();
  const [productId, setProductId] = useState([]);
  const isNonMobile = useMediaQuery("(min-width:600px)");

  const navigate = useNavigate();
  const api = process.env.REACT_APP_API_URL;

  initialValues.fullName = "";
  initialValues.phoneCel = "";
  initialValues.phoneFixo = "";
  initialValues.empresa = "";

  const isDark = () => {
    if(theme.palette.mode === "dark"){
      return "secondary";
    }
    else {
      return "primary";
    }
  };

  //Handle de submit do form
  const handleFormSubmit = async (values) => {
    //seta url para cadastro (POST) e grava o retorno da API no Resp
    const url = `${api}/account/cadastro`;

    const user = {
      fullName : values.fullName,
      email : values.email,
      password : values.password,
      phone: values.phone,
      company : values.company,
      profile : 'admin',
      status : 'active',
      type : values.type,
      identifier : values.identifier,
      product_id: productId,
      total_users: values.total_users,
      product_value: values.value,
      product_duration: values.duration
    };

    const request = await fetch(url,{
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "Access-Control-Allow-Methods": "POST, GET, PUT, DELETE",
        "Access-Control-Allow-Headers": "*",
        "Access-Control-Max-Age": "86400",
      },
      body: JSON.stringify(user),
    });
    setResp(request.status);
    if(request.ok){
      const response = await request.json();
      delay();
    }
  };

  //seta o resp como null sempre que o form for alterado
  const handleFormChange = () => {
    setResp(null);
  };

  const fetchData = async (validaemail) => {
    const url = `${api}/login/validalogin/${validaemail}`;
    try {
      const resp = await fetch(url);
      if (resp.status === 401) {
        setEmailError("Email já cadastrado. Favor usar outro email");
      } else {
        setEmailError(null);
      }
    } catch (error) {
      setEmailError("Erro ao validar o email. Tente novamente mais tarde.");
    }
  };

  const fetchProduct = async () => {
    const url = `${api}/product/allproduct`;
    const resp = await fetch(url);
    const json = await resp.json();
    setProduct(json);
    console.log('product', json);
  };

  useEffect(() => {
    fetchProduct();
  },[]);

  async function delay() {
    await new Promise(resolve => setTimeout(resolve, 2000)); // Tempo em milissegundos (3 segundos neste caso)
    navigate('/login');
  }


  function formatPhoneNumber(phoneNumber) {
    // Remove todos os caracteres não numéricos
    phoneNumber = phoneNumber.replace(/\D/g, '');
    
    // Aplica a máscara de preenchimento
    phoneNumber = phoneNumber.replace(/^(\d{2})(9\d{4})(\d{4})$/, '($1)$2-$3');
    
    return phoneNumber;
  };

  function formatFixedPhoneNumber(phoneNumber) {
    // Remove todos os caracteres não numéricos
    phoneNumber = phoneNumber.replace(/\D/g, '');
  
    // Aplica a máscara de preenchimento
    phoneNumber = phoneNumber.replace(/^(\d{2})(\d{4})(\d{4})$/, '($1)$2-$3');
  
    return phoneNumber;
  }

  const handleValidaCNPJ = (values) => {
    return cnpj.isValid(values);
  };

  const handleValidaTelefone = (phone) => {
    const telefoneRegEx = /^\([1-9]{2}\)9[0-9]{4}\-[0-9]{4}$/;
    if (telefoneRegEx.test(phone)){
      return true;
    }
    else{
      return false;
    }
  };

  const updateResult = (value) => {
    setProductId(value);
    console.log(productId);
  };


  return (
    <Container component="main">
      <Box
          style={{
            display: "flex",
            flexDirection:"row",
            alignContent: "center",
            justifyContent: "center",
            gap:"10px",
            padding:"10px",
            marginTop: theme.spacing(4),
            //backgroundColor: colors.grey[400],
          }}
      >
        
        {/*BOX 1 */}
        <Box
          style={{
            width: "50%",
            display: "grid",
            alignItems: "center",
            //backgroundColor: colors.primary[400],
            padding: "30px",
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
                <img
                alt="profile-user"
                width="100%"
                height="100%"
                src={`/assets/login_allmetrics.png`}
                //style={{ cursor: "pointer", borderRadius: "50%" }}
                />
          </Box>
          
        </Box>
        {/*BOX 2 */}
        <Box
          style={{
            width: "50%",
            display: "grid",
            alignItems: "center",
            //backgroundColor: colors.primary[400],
            borderLeft: "solid 1px " + colors.greenAccent[500],
            //borderRadius: 10,
            borderColor: colors.greenAccent[500],
            padding: "10px",
          }}
        >
          <Typography component="h1" variant="h5" style={{ color: colors.greenAccent[500] }}>
            Novo Cadastro
          </Typography>
          <Box
            style={{marginTop: theme.spacing(1) }}
          >
            <Formik
              onSubmit={handleFormSubmit}
              initialValues={initialValues}
              validationSchema={checkoutSchema}
            >
              {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
              }) => (
                <form onSubmit={handleSubmit} onChange={handleFormChange}>
                  <Box
                    display="grid"
                    gap="30px"
                    gridTemplateColumns="repeat(6, minmax(0, 1fr))"
                    sx={{
                      "& > div": { gridColumn: isNonMobile ? undefined : "span 6" },
                    }}
                  >
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="Nome"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.fullName}
                      name="fullName"
                      error={!!touched.fullName && !!errors.fullName}
                      helperText={touched.fullName && errors.fullName}
                      sx={{ gridColumn: "span 6" }}
                    />
                     <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="E-mail"
                      onBlur={(e)=>{
                        fetchData(values.email);
                      }}
                      onChange={handleChange}
                      value={values.email}
                      name="email"
                      error={!!(touched.email && errors.email) || !!emailError} // Considere o email como erro se houver um erro retornado pela API
                      helperText={touched.email && errors.email ? errors.email : emailError} // Exiba o erro da API se houver, caso contrário, exiba o erro de validação padrão
                      sx={{ gridColumn: "span 6" }}
                    />
                    <TextField
                      fullWidth
                      variant="filled"
                      type="password"
                      label="Senha"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.password}
                      name="password"
                      error={!!touched.password && !!errors.password}
                      helperText={touched.password && errors.password}
                      sx={{ gridColumn: "span 6" }}
                    />
                   <InputMask
                      mask={'(99)99999-9999'}
                      maskPlaceholder={null} // Isso remove o hint de máscara do campo
                      onChange={handleChange}
                      onBlur={(e) => {
                        if(!handleValidaTelefone(e.target.value)){
                          setPhoneError("Telefone inválido");
                        }
                        else{
                          setPhoneError(null);
                        }
                      }}
                      value={values.phone}
                      name="phone"
                      error={!!(touched.phone && errors.phone) || !!phoneError} // Considere o email como erro se houver um erro retornado pela API
                      helperText={touched.phone && errors.phone ? errors.phone : phoneError} 
                      sx={{ gridColumn: "span 6" }}
                    >
                      {(inputProps) => <TextField {...inputProps} fullWidth variant="filled" type="text" label="Telefone" />}
                    </InputMask>
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="Empresa"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.company}
                      name="company"
                      error={!!touched.company && !!errors.company}
                      helperText={touched.company && errors.company}
                      sx={{ gridColumn: "span 6" }}
                    />
                    <FormControl fullWidth sx={{ gridColumn: "span 2" }}>
                      <InputLabel>Identifier </InputLabel>
                      <Select
                        fullWidth
                        variant="filled"
                        label="Type"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.type}
                        name="type"
                        error={!!touched.type && !!errors.type}
                        helperText={touched.type && errors.type}
                        sx={{ gridColumn: "span 2" }}
                        defaultValue="CNPJ"
                      >
                        <MenuItem value="CNPJ">CNPJ</MenuItem>
                        <MenuItem value="NIS">NIS</MenuItem>
                      </Select>
                    </FormControl>
                    <InputMask
                      mask={values.type === 'NIS' ? '999.999.999-99' : '99.999.999/9999-99'}
                      maskPlaceholder={null} // Isso remove o hint de máscara do campo
                      onChange={handleChange}
                      onBlur={(e) => {
                        if(values.type === 'CNPJ'){
                          if(handleValidaCNPJ(e.target.value)){
                            setCnpjError(null);
                          }
                          else{
                            setCnpjError("CPNJ is not valid");
                          }
                        }
                      }}
                      value={values.identifier}
                      name="identifier"
                      error={!!(touched.identifier && errors.identifier) || !!cnpjError} // Considere o email como erro se houver um erro retornado pela API
                      helperText={touched.identifier && errors.identifier ? errors.identifier : cnpjError} 
                      sx={{ gridColumn: "span 4" }}
                    >
                      {(inputProps) => <TextField {...inputProps} fullWidth variant="filled" type="text" label={values.type === "NIS" ? "NIS" : "CNPJ"} />}
                    </InputMask>
                    <MultipleSelectCheckmarks
                      name="productName"
                      sx={{ gridColumn: "span 6" }}
                      names={product ? product : null}
                      handleValue={updateResult}
                      error={!!touched.product && !!errors.product}
                      helperText={touched.product && errors.product}
                      value={values.product}
                      label={"Product"}
                    />
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="Total Users"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.total_users}
                      name="total_users"
                      error={!!touched.total_users && !!errors.total_users}
                      helperText={touched.total_users && errors.total_users}
                      sx={{ gridColumn: "span 2" }}
                    />
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="Valor"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.value}
                      name="value"
                      error={!!touched.value && !!errors.value}
                      helperText={touched.value && errors.value}
                      sx={{ gridColumn: "span 2" }}
                    />
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="Duration (days)"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.duration}
                      name="duration"
                      error={!!touched.duration && !!errors.duration}
                      helperText={touched.duration && errors.duration}
                      sx={{ gridColumn: "span 2" }}
                    />
                  </Box>
                  <SnackBars buttonName="Criar Conta" buttonType="submit" resp={resp} />
                </form>
              )}
            </Formik>
          </Box>
        </Box>
      </Box>
      
    </Container>
  );
};

const passwordRegExp = /^(?=.*[A-Z])(?=.*[!@#$%^&*()-+=])(?=.{8,})/;

const phoneRegExp = /^\([1-9]{2}\)9[0-9]{4}\-[0-9]{4}$/;

const nisRegExp = /^\d{9}$/;

const cnpjRegExp = /^\d{2}\.\d{3}\.\d{3}\/\d{4}\-\d{2}$/;

const checkoutSchema = yup.object().shape({
  fullName: yup.string().required("Required"),
  email: yup.string().email("invalid email").required("Required"),
  password: yup
    .string()
    .matches(passwordRegExp,
      `Senha não atende aos critérios de segurança.
       Deve ter no mínimo 8 caracteres.
       Deve conter uma letra maiúscula.
       Deve conter caracteres especiais: !@#$%^&*`
    )
    .required("Required"),
  phone: yup.string()
    .matches(phoneRegExp,`Telefone inválido`)
    .required("Required"),
  company: yup.string().required("Required"),
  identifier: yup.string().required("Required")
});
const initialValues = {
  fullName: "",
  email: "",
  password: "",
  phone: "",
  company: "",
  type: "CNPJ",
  identifier: "",
};

export default FormCadAccount;
