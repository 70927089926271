import { Box, TextField, useTheme, Typography } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import SnackBars from "../../components/CustomizedSnackBars";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { tokens } from "../../theme";
import CustomizedRating from "../../components/CustomizedRating";
import { useNavigate } from "react-router-dom";
import '../../utils/i18n.js';
import { useTranslation } from 'react-i18next';


const FormHappinessRadar = ({title, subTitle}) => {
  const {t, i18n } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [resp, setResp] = useState(null);

  const api = process.env.REACT_APP_API_URL;

  const {user_id, campaign_id} = useParams();
  const navigate = useNavigate();

  const [data,setData] = useState([]);

  let classif = new Map([]);
  let keys = new Map([]);

  const childToParent = (score, item, classification) => {
    if(keys.has(item)){
      keys.set(item,score);
      classif.set(item,classification);
    }
    else{
      keys.set(item, score);
      classif.set(item,classification);
    }
  };

  const handleDataHora = (dataHora) => {
    let dataFormatada = new Date();
    dataFormatada = dataHora.split('/').reverse().join('-');
    return dataFormatada;
  };

  //Handle de submit do form
  const handleFormSubmit = async (values) => {
    const url = `${api}/happinessradar/cadastro`;
    
    keys.forEach(async function(score,item){
      const myDate = handleDataHora(new Date(Date.now()).toLocaleString().split(',')[0].trim());
      const myHour = new Date(Date.now()).toLocaleString().split(',')[1].trim();
      const answer = {
        item : item,
        feeling : "",
        score : parseFloat(score),
        classification : classif.get(item),
        answer_type : "happinessradar",
        realization_date : myDate,
        realization_time : myHour,
        campaign_id : parseInt(campaign_id),
        user_id : parseInt(user_id)
      };
      const request = await fetch(url,{
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Methods": "POST, GET, PUT, DELETE",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Max-Age": "86400",
        },
        body: JSON.stringify(answer),
      });
      setResp(request.status);
      if(request.status >= 200 && request.status <= 299){
        navigate("/redirect");
      }
    });    
  };
  //------------------------------------------------

  return (
    <Box m="20px">
      <Header title={t("HAPPINESS RADAR")} subtitle={t("Enter your level of satisfaction")} />

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        //validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit} >
            <Box
              display="grid"
              gap="8px"
              gridTemplateColumns="repeat(3, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 3" },
              }}
            >
              <Box
                sx={{
                  gridColumn: "span 4",
                }}
              >
                <Typography 
                  component="legend"
                  variant="h4"
                >
                  {t("People and Interactions")}
                </Typography>
              </Box>
              <CustomizedRating
                gridColumn="span 1"
                defaultValue={0}
                precision={0.5}
                size="large"
                title={t("How satisfied are you with the communication?")}
                name={t("Communication")}
                childToParent={childToParent}
                classification={t("People and Interactions")}
              />
              <CustomizedRating
                gridColumn="span 1"
                defaultValue={0}
                precision={0.5}
                size="large"
                title={t("How satisfied are you with the collaboration?")}
                name={t("Collaboration")}
                childToParent={childToParent}
                classification={t("People and Interactions")}
              />
              <CustomizedRating
                gridColumn="span 1"
                defaultValue={0}
                precision={0.5}
                size="large"
                title={t("How satisfied are you with people?")}
                name={t("People")}
                childToParent={childToParent}
                classification={t("People and Interactions")}
              />
              <Box
                sx={{
                  gridColumn: "span 4",
                }}
              >
                <Typography 
                  component="legend"
                  variant="h4"
                >
                  {t("Quality of Life")}
                </Typography>
              </Box>
              <CustomizedRating
                gridColumn="span 1"
                defaultValue={0}
                precision={0.5}
                size="large"
                title={t("How satisfied are you with the workload?")}
                name={t("Workload")}
                childToParent={childToParent}
                classification={t("Quality of Life")}
              />
              <CustomizedRating
                gridColumn="span 1"
                defaultValue={0}
                precision={0.5}
                size="large"
                title={t("How satisfied are you with the working hours?")}
                name={t("Working Hours")}
                childToParent={childToParent}
                classification={t("Quality of Life")}
              />
              <CustomizedRating
                gridColumn="span 1"
                defaultValue={0}
                precision={0.5}
                size="large"
                title={t("Are you managing to balance your personal life with work?")}
                name={t("Balance")}
                childToParent={childToParent}
                classification={t("Quality of Life")}
              />
              <Box
                sx={{
                  gridColumn: "span 4",
                }}
              >
                <Typography 
                  component="legend"
                  variant="h4"
                >
                  {t("Work Processes")}
                </Typography>
              </Box>
              <CustomizedRating
                gridColumn="span 1"
                defaultValue={0}
                precision={0.5}
                size="large"
                title={t("How satisfied are you with the work tools?")}
                name={t("Work Tools")}
                childToParent={childToParent}
                classification={t("Work Processes")}
              />
              <CustomizedRating
                gridColumn="span 1"
                defaultValue={0}
                precision={0.5}
                size="large"
                title={t("How satisfied are you with the work process?")}
                name={t("Work Process")}
                childToParent={childToParent}
                classification={t("Work Processes")}
              />
              <CustomizedRating
                gridColumn="span 1"
                defaultValue={0}
                precision={0.5}
                size="large"
                title={t("How satisfied are you with the Enviroment?")}
                name={t("Enviroment")}
                childToParent={childToParent}
                classification={t("Work Processes")}
              />
              <Box
                sx={{
                  gridColumn: "span 4",
                }}
              >
                <Typography 
                  component="legend"
                  variant="h4"
                >
                  {t("Career")}
                </Typography>
              </Box>
              <CustomizedRating
                gridColumn="span 1"
                defaultValue={0}
                precision={0.5}
                size="large"
                title={t("How do you evaluate the development and promotion opportunities offered?")}
                name={t("Opportunities")}
                childToParent={childToParent}
                classification={t("Career")}
              />
              <CustomizedRating
                gridColumn="span 1"
                defaultValue={0}
                precision={0.5}
                size="large"para refinamento negocial
                title={t("How satisfied are you with your total compensation?")}
                name={t("Compensation")}
                childToParent={childToParent}
                classification={t("Career")}
              />
              <CustomizedRating
                gridColumn="span 1"
                defaultValue={0}
                precision={0.5}
                size="large"
                title={t("How satisfied are you with the benefits offered?")}
                name={t("Benefits")}
                childToParent={childToParent}
                classification={t("Career")}
              />
            </Box>
            <SnackBars buttonName={t("FINISH")} buttonType="submit" resp={resp} />
          </form>
        )}
      </Formik>
    </Box>
  );
};

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const checkoutSchema = yup.object().shape({
  safety_check: yup.string().required("required"),
});
const initialValues = {
  safety_check: "",
};

export default FormHappinessRadar;