import { Button, Box, TextField, Container, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import RecommendOutlinedIcon from '@mui/icons-material/RecommendOutlined';
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { useContext, useState } from "react";
import TeamProfile from "../../hooks/useTeam";
import LoginSnackBar from "../../components/LoginSnackBars";
import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from "jwt-decode";
import { tokens, useMode } from "../../theme";
import LoginProfile from "../../hooks/useLoginUser";
import { Context } from "../../components/Auth";
import { Formik } from "formik";
import useMediaQuery from "@mui/material/useMediaQuery";
import * as yup from "yup";


const Recovery = () => {
  const [theme, colorMode] = useMode();
  const colors = tokens(theme.palette.mode);
  //const [resp, setResp] = useState(null);
  const isNonMobile = useMediaQuery("(min-width:600px)");

  let resp = null;

  const { authenticated, handleLogin } = useContext(Context);

  const navigate = useNavigate();
  const api = process.env.REACT_APP_API_URL;

  initialValues.fullName = "";

  const isDark = () => {
    if(theme.palette.mode === "dark"){
      return "secondary";
    }
    else {
      return "primary";
    }
  };

  //Handle de submit do form
  const handleFormSubmit = async (values) => {
    //seta url para cadastro (POST) e grava o retorno da API no Resp
    const url = `${api}/login/recovery`;

    const user = {
      email : values.email,
    };
    const request = await fetch(url,{
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "Access-Control-Allow-Methods": "POST, GET, PUT, DELETE",
        "Access-Control-Allow-Headers": "Content-Type, Authorization",
        "Access-Control-Max-Age": "86400",
        "Acceped": "*/*",
      },
      body: JSON.stringify(user),
    });
    if (request.ok) {
      if(parseInt(request.status) === 200){
        resp = parseInt(request.status);
      }      
    } else {
      resp = parseInt(request.status);
    }
  }

  //seta o resp como null sempre que o form for alterado
  const handleFormChange = () => {
    //setResp(null);
    resp = null;
  };

  return (
    <Container component="main">
      <Box
          style={{
            display: "flex",
            flexDirection:"row",
            alignContent: "center",
            justifyContent: "center",
            gap:"10px",
            padding:"10px",
            marginTop: theme.spacing(8),
            //backgroundColor: colors.grey[400],
          }}
      >
        
        {/*BOX 1 */}
        <Box
          style={{
            width: "50%",
            display: "grid",
            alignItems: "center",
            //backgroundColor: colors.primary[400],
            padding: 30,
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
                <img
                alt="profile-user"
                width="100%"
                height="100%"
                src={`/assets/login_allmetrics.png`}
                //style={{ cursor: "pointer", borderRadius: "50%" }}
                />
          </Box>
          
        </Box>
        {/*BOX 2 */}
        <Box
          style={{
            width: "50%",
            display: "grid",
            alignItems: "flex-start",
            borderLeft: "solid 1px " + colors.greenAccent[500],
            borderColor: colors.greenAccent[500],
            padding: 20,
          }}
        >
          <Typography component="h1" variant="h5" style={{ color: colors.greenAccent[500] }}>
            Can't sign in?
          </Typography>
          
          <Box>
            <Formik
              onSubmit={handleFormSubmit}
              initialValues={initialValues}
              validationSchema={checkoutSchema}
            >
              {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
              }) => (
                <form onSubmit={handleSubmit} onChange={handleFormChange}>
                  <h4>We'll send a recovery link to:</h4>
                  <Box
                    display="grid"
                    gap="30px"
                    gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                    sx={{
                      "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                    }}
                  >
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="E-mail"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.email}
                      name="email"
                      error={!!touched.email && !!errors.email}
                      helperText={touched.email && errors.email}
                      sx={{ gridColumn: "span 4" }}
                    />
                  </Box>
                  <LoginSnackBar buttonName="Send" buttonType="submit" resp={resp} />
                </form>
              )}
            </Formik>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

const passwordRegExp = /^(?=.*[A-Z])(?=.*[!@#$%^&*()-+=])(?=.{8,})/;

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const checkoutSchema = yup.object().shape({
  email: yup.string().email("invalid email").required("required")
});
const initialValues = {
  email: "",
};

export default Recovery;
