import { Box, useTheme, MenuItem, Typography, FormControl, InputLabel, Select } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { ColorModeContext, tokens } from "../../theme";
import { useNavigate, useLocation } from 'react-router-dom';
import '../../utils/i18n';
import { useTranslation } from 'react-i18next';

const AlternateTopbar = () => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const [teamSelected, setTeamSelected] = useState(null);
  const [lenguageSelected, setLengagueSelected] = useState(null);
  const [currentLanguage, setCurrentLanguage] = useState('pt');

  const api = process.env.REACT_APP_API_URL;

  const navigate = useNavigate();
  const location = useLocation();
  
  const [team, setTeam] = useState([]);

  const changeLanguage = value => {
    i18n.changeLanguage(value)
    .then(() => {
      setCurrentLanguage(value);
      localStorage.setItem('language',value);
    })
    .catch((err) => {
      console.log(err);
    })
  };

  const setLanguage = () => {
    if(!lenguageSelected){
      setLengagueSelected(localStorage.getItem('language') ? localStorage.getItem('language') : currentLanguage);
    }
    return localStorage.getItem('language') ? localStorage.getItem('language') : currentLanguage;
  };

  useEffect(() => {
    setLanguage();
  },[]);

  /*
  const fetchData = async () => {
    const url = `${api}/allteams/${localStorage.getItem('account_id')}`;
    const resp = await fetch(url);
    const json = await resp.json();
    setTeam(json);

    //selecionar automaticamente o primeiro time
    localStorage.setItem('team_id',json[0].id);
    TeamProfile.setName(json[0].id);
    setTeamSelected(json[0].teamName);
  };

  useEffect(() => {
    fetchData();
  },[localStorage.getItem('alter_teams'),localStorage.getItem('account_id')]);
  */


  const handleChange = (event) => {
    setLengagueSelected(event.target.value);
    changeLanguage(event.target.value);
  };

  return (
    
    <Box display="flex" flexDirection="column" marginTop="2vh">
      {/* SEARCH BAR */}
      <Box display="flex" justifyContent="center" alignItems="center">
                <img
                  alt="profile-user"
                  width="70px"
                  height="70px"
                  src={`/assets/logo_All_Metrics-removebg-preview-v4.png`}
                  style={{ cursor: "pointer", borderRadius: "50%" }}
                />
              </Box>
              <Box textAlign="center">
                <Typography
                  variant="h3"
                  color={colors.grey[100]}
                  fontWeight="bold"
                  sx={{ m: "10px 0 0 0" }}
                >
                  All Metrics
                </Typography>
                <Typography variant="h6" color={colors.greenAccent[500]}>
                  Good Decisions
                </Typography>
              </Box>

      {/* ICONS */}
    </Box>
  );
};

export default AlternateTopbar;
