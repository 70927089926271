import { Box, Button, FormControl, InputLabel, Select, MenuItem, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import TrendingUpOutlinedIcon from '@mui/icons-material/TrendingUpOutlined';
import TrendingFlatOutlinedIcon from '@mui/icons-material/TrendingFlatOutlined';
import TrendingDownOutlinedIcon from '@mui/icons-material/TrendingDownOutlined';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import Header from "../../components/Header";
import StatBox from "../../components/StatBox";
import { useParams, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import TeamProfile from "../../hooks/useTeam";
import LoginProfile from "../../hooks/useLoginUser";
import '../../utils/i18n';
import { useTranslation } from 'react-i18next';
import DrawerFilterSatisfaction from "../../components/DrawerFilterSatisfaction";


const mocklineChart = [
  {
    "id": 0,
    "data": [
        {
            "x": "",
            "y": 0
        },
    ]
  }
];

const mockKeyLineChart = ['People'];

const PerformanceDashboard = () => {
  const {t, i18n} = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [dataGrid, setDataGrid] = useState([]);
  const [bigNumber, setBigNumber] = useState([]);
  const [list, setList] = useState([]);
  const [option, setOption] = useState(0);
  const [filter, setFilter] = useState({
    selectTeam: 0,
    people_target: 0,
    isFilter: false,
  });

  const location = useLocation();

  const api = process.env.REACT_APP_API_URL;
  
  //const {campaign_id} = useParams();

  useEffect(() => {
    const fetchData = async () => {
      const fetchWithHeaders = async (url) => {
        const options = {
          method: 'GET',
          headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Methods": "POST, GET, PUT, DELETE",
              "Access-Control-Allow-Headers": "*",
              "Access-Control-Max-Age": "86400",
              "Authorization": sessionStorage.getItem('token')
          }
        };
        const resp = await fetch(url, options);
        return resp.json();

      };
      try{
        const [dataGridResponse, countuserbyscoreResponse] = await Promise.all([
          fetchWithHeaders(`${api}/assessment/performancelist/${LoginProfile.getUserId()}/team/${filter.selectTeam}/people_target/${filter.people_target}`),
          fetchWithHeaders(`${api}/assessment/countuserbyscore/${LoginProfile.getUserId()}/team/${filter.selectTeam}/people_target/${filter.people_target}`),
        ]);
        setDataGrid(dataGridResponse);
        setBigNumber(countuserbyscoreResponse);
      }
      catch (error) {
        console.error("Error fetching data", error);
      }

    };

    //fetchRadarChart();
    fetchData();
    //fetchDataGrid();
  }, [filter]);

  let above_expectations;
  let met_expectations;
  let needs_improvement;
  let below_expectations;
  

  bigNumber.map((itens) => {
    above_expectations = itens.above_expectations;
    met_expectations = itens.met_expectations;
    needs_improvement = itens.needs_improvement;
    below_expectations = itens.below_expectations;
  })

  const handleIncrease = (score) => {
    let total = above_expectations + met_expectations + needs_improvement + below_expectations;
    let result = (score / total) * 100;
    return result;
  };

  const handleToFixedIncrease = (score) => {
    return score.toFixed(0);
  };

  const handleDrawerToggle = () => {
    setOpenDrawer(!openDrawer);
  };

  const handleFiltersChange = (selectFilters) => {
    setFilter(selectFilters);
    console.log("filtro aplicado", selectFilters);
  };

  const handleChange = (event) => {
    setOption(event.target.value);
  }

  const columns = [
    {field: "id", headerName: "ID",flex:0.2},
    {
      field: "user",
      headerName: t("Employee"),
      flex: 2,
      cellClassName: "name-column--cell",
    },
    {
      field: "manager",
      headerName: t("Manager"),
      flex: 2,
    },
    {
      field: "team",
      headerName: t("Team"),
      flex: 2,
    },
    {
      field: "Hardskill",
      headerName: t("Hardskill"),
      flex: 1,
    },
    {
      field: "Softskill",
      headerName: t("Softskill"),
      flex: 1,
    },
    {
      field: "average_score",
      headerName: t("Avarege Score"),
      flex: 1,
      
    },
    {
      field: "qtd_assessment",
      headerName: t("Total Assessment"),
      flex: 1,
    },
    {
      field: "classification",
      headerName: "Classification",
      flex: 2,
      renderCell: ({ row: { classification } }) => {
        return (
          <Box
            width="80%"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor={
              classification === "Above expectations"
                ? colors.greenAccent[700]
                : classification === "Met expectations"
                ? colors.grey[600]
                : classification === "Needs improvements"
                ? "#FF6347"
                : colors.redAccent[700]
            }
            borderRadius="4px"
          >
            {classification === "Above expectations" && <TrendingUpOutlinedIcon />}
            {classification === "Met expectations" && <TrendingFlatOutlinedIcon />}
            {classification === "Below expectations" && <TrendingDownOutlinedIcon />}
            {classification === "Needs improvements" && <RefreshOutlinedIcon />}
            <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
              {classification}
            </Typography>
          </Box>
        );
      },
    },
  ];

  return (
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title={t("Performance Dashboard")} subtitle={t("Welcome to satisfaction level dashboard")} />
        <Box>
          <Button
            onClick={handleDrawerToggle}
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: filter.isFilter === true ? colors.greenAccent[400] : colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
          >
            {filter.isFilter === true ? <FilterAltOutlinedIcon sx={{ mr: "10px" }} /> : <TuneOutlinedIcon sx={{ mr: "10px" }} />}
            {t("Filter")}
          </Button>
        </Box>
      </Box>
      <DrawerFilterSatisfaction 
        open={openDrawer}
        onClose={handleDrawerToggle}
        onApplyFilters={handleFiltersChange}
      />
      {/* GRID & CHARTS */}
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
      >
        {/* ROW 1 */}
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
          borderRadius="10px"
        >
          <StatBox
            title={above_expectations ? handleToFixedIncrease(above_expectations) : 0}
            subtitle={t("Above Expectations")}
            progress={handleIncrease(above_expectations) / 100}
            increase={handleIncrease(above_expectations).toFixed(0) + "%"}
            icon={
              <TrendingUpOutlinedIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
          borderRadius="10px"
        >
          <StatBox
            title={met_expectations ? handleToFixedIncrease(met_expectations) : 0}
            subtitle={t("Met Expectations")}
            progress={handleIncrease(met_expectations) / 100}
            increase={handleIncrease(met_expectations).toFixed(0) + "%"}
            icon={
              <TrendingFlatOutlinedIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
          borderRadius="10px"
        >
          <StatBox
            title={needs_improvement ? handleToFixedIncrease(needs_improvement) : 0}
            subtitle={t("Needs Improvements")}
            progress={handleIncrease(needs_improvement) / 100}
            increase={handleIncrease(needs_improvement).toFixed(0) + "%"}
            icon={
              <RefreshOutlinedIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
          borderRadius="10px"
        >
          <StatBox
            title={below_expectations ? handleToFixedIncrease(below_expectations) : 0}
            subtitle={t("Below Expectations")}
            progress={handleIncrease(below_expectations) / 100}
            increase={handleIncrease(below_expectations).toFixed(0) + "%"}
            icon={
              <TrendingDownOutlinedIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        {/**Row 2 */}
        <Box
          gridColumn="span 12"
          gridRow="span 4"
          //backgroundColor={colors.primary[400]}
          p="5px"
          m="10px 0 0 0"
          borderRadius="10px"
        >
          <Typography variant="h4" fontWeight="600">
            {t("Performance List")}
          </Typography>
          {/** Vazio */}
          <Box
            m="5px 0 0 0"
            height="60vh"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .name-column--cell": {
                color: colors.greenAccent[300],
              },
              "& .MuiDataGrid-row": {
                borderBottom: "solid 1px",
                borderBottomColor: colors.grey[600],
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.blueAccent[700],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400],
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: colors.blueAccent[700],
              },
              "& .MuiCheckbox-root": {
                color: `${colors.greenAccent[200]} !important`,
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${colors.grey[100]} !important`,
              },
            }}
          >
            <DataGrid 
                checkboxSelection
                rows={dataGrid ? dataGrid : false }
                columns={columns}
                components={{ Toolbar: GridToolbar }}
                onSelectionModelChange={setList}
              />
          </Box>
        </Box>
        {/** Next Row */}
      </Box>
    </Box>
  );
};

export default PerformanceDashboard;
