import { Box, TextField } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../../components/Header";
import SnackBars from "../../../components/CustomizedSnackBars";
import { useState } from "react";
import { useParams } from "react-router-dom";
import {useFetch} from '../../../hooks/useFetch';


const FormIteration = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const[method,setMethod] = useState(null);
  const [resp, setResp] = useState(null);

  const api = process.env.REACT_APP_API_URL;

  //recebe id do account para carregar o form para edição
  const { id } = useParams();
  //----------------------------------------------------

  const urlGetIteration = `${api}/iteration/${id}`;
  const { data } = useFetch(urlGetIteration);

  initialValues.iteration_name = `${data.iteration_name ? data.iteration_name : ""}`;
  initialValues.initial_date = `${data.initial_date ? data.initial_date : ""}`;
  initialValues.end_date = `${data.end_date ? data.end_date : ""}`;
  initialValues.planning = `${data.planning ? data.planning : ""}`;

  //seta o resp como null sempre que o form for alterado
  const handleFormChange = () => {
    setResp(null);
  };
  //--------------------------------------------------

  //Handle de submit do form
  const handleFormSubmit = async (values) => {
    const url = `${api}/iteration/cadastro`;

    const iteration = {
      iteration_id : id,
      iteration_name : values.iteration_name,
      initial_date : values.initial_date,
      end_date : values.end_date,
      planning : values.planning,
    };
    const request = await fetch(url,{
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "Access-Control-Allow-Methods": "POST, GET, PUT, DELETE",
        "Access-Control-Allow-Headers": "*",
        "Access-Control-Max-Age": "86400",
      },
      body: JSON.stringify(iteration),
    });
    setResp(request.status);
    
  };
  //------------------------------------------------

  return (
    <Box m="20px">
      <Header title="CREATE ITERATION" subtitle="Create a New Iteration " />

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit} onChange={handleFormChange}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="iteration"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.iteration_name}
                name="iteration_name"
                error={!!touched.iteration && !!errors.iteration}
                helperText={touched.iteration && errors.iteration}
                sx={{ gridColumn: "span 4" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Start Date"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.initial_date}
                name="initial_date"
                error={!!touched.initial_date && !!errors.initial_date}
                helperText={touched.initial_date && errors.initial_date}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="End Date"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.end_date}
                name="end_date"
                error={!!touched.end_date && !!errors.end_date}
                helperText={touched.end_date && errors.end_date}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="planning"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.planning}
                name="planning"
                error={!!touched.planning && !!errors.planning}
                helperText={touched.planning && errors.planning}
                sx={{ gridColumn: "span 4" }}
              />
            </Box>
            <SnackBars buttonName="Create New Iteration" buttonType="submit" resp={resp} />
          </form>
        )}
      </Formik>
    </Box>
  );
};

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const checkoutSchema = yup.object().shape({
  iteration_name: yup.string().required("required"),
  initial_date: yup.string().required("required"),
  end_date: yup.string().required("required"),
  planning: yup.string().required("required"),
});
const initialValues = {
  iteration: "",
  initial_date: "",
  end_date: "",
  planning: "",
};

export default FormIteration;