import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../theme";
import ProgressCircle from "./ProgressCircle";

const StatAction = ({ title, subtitle, icon, progress, increase }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box width="100%" m="0 30px">
      <Box display="flex" justifyContent="space-around" textAlign="center">
        <Box>
        <Typography variant="h5" sx={{ color: colors.greenAccent[500]}} >
          {subtitle}
        </Typography>  
          <Typography
            variant="h1"
            fontWeight="bold"
            sx={{ color: colors.grey[100] }}
          >
            {title}
          </Typography>
        </Box>
      </Box>
      <Box display="flex" justifyContent="space-around" mt="2px">
      {icon}
      </Box>
    </Box>
  );
};

export default StatAction;
