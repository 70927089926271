var TeamProfile = (function() {
    var profile_account_id;
    var profile_team_name;
    var profile_team_id;
    var profile_campaign_id;
  
    var getTeamID = function() {
      return profile_team_id;    // Or pull this from cookie/localStorage
    };
  
    var setTeamID = function(team_id) {
        profile_team_id = team_id;     
      // Also set this in cookie/localStorage
    };

    var getAccountID = function() {
      return profile_account_id;    // Or pull this from cookie/localStorage
    };
  
    var setAccountID = function(account_id) {
        profile_account_id = account_id;     
      // Also set this in cookie/localStorage
    };

    var getCampaignID = function() {
      return profile_campaign_id;    // Or pull this from cookie/localStorage
    };
  
    var setCampaignID = function(campaign_id) {
      profile_campaign_id = campaign_id;     
      // Also set this in cookie/localStorage
    };

    var getTeamName = function(){
      return profile_team_name;
    };

    var setTeamName = function(team_name){
      profile_team_name = team_name;
    }
  
    return {
      getTeamID: getTeamID,
      setTeamID: setTeamID,
      getAccountID: getAccountID,
      setAccountID: setAccountID,
      getCampaignID: getCampaignID,
      setCampaignID: setCampaignID,
      getTeamName: getTeamName,
      setTeamName: setTeamName
    }
  
  })();
  
  export default TeamProfile;