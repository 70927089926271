import React, { createContext, useState } from "react";
import { useNavigate } from "react-router-dom";

const Context = createContext();

function AuthProvider({ children }){
  const navigate = useNavigate();

  const [authenticated, setAuthenticated] = useState(false);

  function handleLogin() {
    setAuthenticated(true);   
    navigate('/dashboard/satisfaction');
  }

  function handleLogout() {
    sessionStorage.removeItem('token');
    setAuthenticated(false);
    navigate('/login')
  }

  return (
    <Context.Provider value={{ authenticated, handleLogin, handleLogout }}>
      { children }
    </Context.Provider>
  );
}

export { Context, AuthProvider };