import { useState, useEffect } from "react"

export const useFetch = (url) => {
    const [data,setData] = useState([])

    //post
    const [config,setConfig] = useState(null);
    const [method,setMethod] = useState(null);
    const [callFetch,setCallFetch] = useState(false);

    const httpConfig = (data, method) => {
        if(method === 'POST') {
            setConfig({
                method,
                headers: {
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "http://localhost:3000",
                    "Access-Control-Allow-Methods": "POST, GET",
                    "Access-Control-Allow-Headers": "*",
                    "Access-Control-Max-Age": "86400",
                    "Authorization": `${sessionStorage.getItem('token')}`
                },
                body: JSON.stringify(data),
            });
        }
    };

    useEffect(() => {
        const fetchData = async () =>{
            const options = {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "http://localhost:3000",
                    "Access-Control-Allow-Methods": "POST, GET, PUT, DELETE",
                    "Access-Control-Allow-Headers": "*",
                    "Access-Control-Max-Age": "86400",
                    "Authorization": `${sessionStorage.getItem('token')}`
                  }
              };
            const resp = await fetch(url, options);

            const json = await resp.json();

            setData(json);
        };

        fetchData();
    }, [url, callFetch]);

    //fetch do post
    useEffect(() => {
        const httpRequest = async () => {
            if(method === 'PUT'){
                let fetchOptions = [url, config];
                
                const res = await fetch(...fetchOptions);

                const json = await res.json();

                setCallFetch(json);
            }
        };
        httpRequest();
    }, [config]);

    return {data};
}