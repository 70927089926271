import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import { InputLabel } from "@mui/material";
import { tokens } from '../theme';
import '../utils/i18n';
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import LoginProfile from "../hooks/useLoginUser";
import { useResolvedPath } from 'react-router-dom';


export default function AnchorTemporaryDrawer({ open, onClose, onApplyFilters, newFilter }) {
  const { t, i18n } = useTranslation();
  const [selectTeam, setSelectTeam] = useState(0);
  const [listTeam, setListTeam] = useState([]);
  const [people_target, setPeopleTarget] = useState(0);
  const [isFilter, setIsFilter] = useState(false);

  const api = process.env.REACT_APP_API_URL;

  const theme = useTheme();
  const colors = tokens(theme.palette.mode); // Ajusta para pegar o tema (claro/escuro)


  const handleApplyFilters = () => {
    // Aqui você pode adicionar lógica para aplicar os filtros
    const selectFilters = {
      selectTeam,
      people_target,
      isFilter:true,
    }
    setIsFilter(true);
    onApplyFilters(selectFilters);
    onClose(); // Fechar o drawer após aplicar os filtros
  };

  const handleClearFilters = () => {
    const selectFilters = {
      selectTeam: 0,
      people_target: 0,
      selectNewFilter: 0,
      isFilter: false,
    }
    setIsFilter(false);
    setSelectTeam(0);
    setPeopleTarget(0);
    onApplyFilters(selectFilters);
    onClose();
  }

  useEffect(() => {
    const fetchData = async () => {
      const url = `${api}/team/manager/${LoginProfile.getUserId()}`;
      const options = {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "Access-Control-Allow-Methods": "POST, GET, PUT, DELETE",
            "Access-Control-Allow-Headers": "*",
            "Access-Control-Max-Age": "86400",
            "Authorization": sessionStorage.getItem('token')
        }
      };
      const resp = await fetch(url, options);
      return resp.json();
      if(resp.ok){
        setListTeam(resp.json());
      }

    };
    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const list = (anchor) => (
    <Box
      sx={{
        width: 250,
        padding: 2,
        backgroundColor: colors.primary[400],
        color: colors.grey[100],
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap:"20px"
      }}
      role="presentation"
      onKeyDown={onClose}
    >
      <h3>Filter Options</h3>
      {/* Select Dropdown */}
      <FormControl fullWidth sx={{ marginTop: 2 }}>
      <InputLabel>{t("Team")}</InputLabel>
        <Select
          name='team'
          variant="filled"
          value={selectTeam}
          onChange={(event) => { setSelectTeam(event.target.value) }}
          displayEmpty
        >
          {listTeam && listTeam.map((item) => (
            <MenuItem value={item.id}>{item.teamName}</MenuItem>
          ))}
        </Select>
      </FormControl>
      {/* Select Dropdown */}
      <FormControl fullWidth sx={{ marginTop: 2 }}>
      <InputLabel>{t("People Target")}</InputLabel>
        <Select
          name='people_target'
          variant="filled"
          value={people_target}
          onChange={(event) => { setPeopleTarget(event.target.value) }}
          displayEmpty
        >
          <MenuItem value={t("Strategic")}>{t("Strategic")}</MenuItem>
          <MenuItem value={t("Tatical")}>{t("Tatical")}</MenuItem>
          <MenuItem value={t("Operational")}>{t("Operational")}</MenuItem>
        </Select>
      </FormControl>
      {/* Add new filter in list */}
      {newFilter}
      {/* Apply Filters Button */}
      <Box>
      <Button
        fullWidth
        variant="contained"
        sx={{ marginTop: 3 }}
        onClick={handleApplyFilters}
        color="secondary"
      >
        Apply Filters
      </Button>
       {/* Clear Filters Button */}
       <Button
        fullWidth
        variant="contained"
        sx={{ marginTop: 3 }}
        onClick={handleClearFilters}
        color="primary"
      >
        Clear Filters
      </Button>
      </Box>
    </Box>
  );

  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      {list('right')}
    </Drawer>
  );
}
