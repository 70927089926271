import { ResponsiveHeatMap } from '@nivo/heatmap'
import { useTheme } from "@mui/material";
import { tokens } from "../theme";


const MyResponsiveHeatMap = ({ data }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    
    return (
        <ResponsiveHeatMap
            data={data}
            theme={{
              // added
              axis: {
                domain: {
                  line: {
                    stroke: colors.grey[100],
                  },
                },
                ticks: {
                  line: {
                    stroke: colors.grey[100],
                    strokeWidth: 1,
                  },
                  text: {
                    fill: colors.grey[100],
                  },
                },
              },
              legends: {
                title:{
                  text: {
                    fill: colors.grey[100],
                  },
                },
                ticks: {
                  text: {
                    fill: colors.grey[100],
                  },
                },
              },
            }}
            margin={{ top: 90, right: 90, bottom: 60, left: 90 }}
            valueFormat=">-.2r"
            isInteractive={false}
            axisTop={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: -90,
                legend: '',
                legendOffset: 46,
                truncateTickAt: 15
            }}
            axisRight={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legendPosition: 'middle',
                legendOffset: 70,
                truncateTickAt: 12
            }}
            axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legendPosition: 'middle',
                legendOffset: -72,
                truncateTickAt: 12,
            }}
            colors={{
                type: 'diverging',
                scheme: 'red_yellow_green',
                divergeAt: 0.6,
                minValue: 0, //Determina a faixa de valores da legenda
                maxValue: 5 //Determina a faixa de valores da legenda
            }}
            emptyColor="#555555"
            borderWidth={1}
            borderColor="#ffffff"
            legends={[
                {
                    anchor: 'bottom',
                    translateX: 0,
                    translateY: 30,
                    length: 400,
                    thickness: 8,
                    direction: 'row',
                    tickPosition: 'after',
                    tickSize: 3,
                    tickSpacing: 4,
                    tickOverlap: false,
                    tickFormat: '>-.2r',
                    title: 'Score →',
                    titleAlign: 'start',
                    titleOffset: 4
                }
            ]}
        />
    )
};

export default MyResponsiveHeatMap;