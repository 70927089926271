import { FormHelperText, useTheme, Select, FormControl, InputLabel, MenuItem, Checkbox, ListItemText } from "@mui/material";
import { useContext, useState } from "react";
import { ColorModeContext, tokens } from "../theme";
import {useField, useFormikContext} from "formik";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


export default function MultipleSelectCheckmarks({sx, names, handleValue, label="Team", ...props}) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const [field, meta] = useField(props.name);
  const { setFieldValue } = useFormikContext();
  const [personName, setPersonName] = useState([]);

  const handleChange = (e) => {
    const {
      target: { value },
    } = e;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
    handleValue(value);
    setFieldValue(props.name, value);
  };
  

  return (
    <FormControl fullWidth sx={sx} error={!!meta.touched && !!meta.error}>
    <InputLabel>{label}</InputLabel>
    <Select
        fullWidth
        variant="filled"
        labelId="demo-multiple-checkbox-label"
        label="Team"
        id="demo-multiple-checkbox"
        multiple
        value={personName}
        onChange={handleChange}
        renderValue={(selected) =>
            selected.map((id) => names.find((name) => name.id === id).teamName).join(', ')
          }
        MenuProps={MenuProps}
        >
        {names && names.map((name) => (
        <MenuItem
            key={name.id}
            value={name.id}
            label={name.teamName}
            sx={{
                backgroundColor: personName.includes(name.id) ? colors.grey[500] : 'inherit',
                '&.Mui-selected': {
                  backgroundColor: colors.grey[500],
                },
                '&.Mui-selected:hover': {
                  backgroundColor: colors.grey[700],
                },
              }}
            >
            <Checkbox checked={personName.indexOf(name.id) > -1} />
            <ListItemText
                primary={name.teamName}
            />
        </MenuItem>
        ))}
    </Select>
      {meta.touched && meta.error && (
        <FormHelperText>{meta.error}</FormHelperText>
      )}
    </FormControl>
  );
}