import { Box, TextField, useTheme, Typography } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import SnackBars from "../../components/CustomizedSnackBars";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { tokens } from "../../theme";
import CustomizedRating from "../../components/CustomizedRating";
import { useNavigate } from "react-router-dom";
import '../../utils/i18n';
import { useTranslation } from 'react-i18next';


const FormEnergyMotivation = () => {
  const {t, i18n } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [resp, setResp] = useState(null);
  const [selectedValue, setSelectedValue] = useState(null);
  const [item, setItem] = useState(null);
  const [classification, setClassification] = useState(null);

  const navigate = useNavigate();

  const api = process.env.REACT_APP_API_URL;

  const {user_id, campaign_id} = useParams();

  const [dataMotivacao,setDataMotivacao] = useState();
  const childToParentMotivacao = (childData) => {
    setDataMotivacao(childData);
  }

  const [dataEnergia,setDataEnergia] = useState();
  const childToParentEnergia = (childData) => {
    setDataEnergia(childData);
  }

  const handleDataHora = (dataHora) => {
    let dataFormatada = new Date();
    dataFormatada = dataHora.split('/').reverse().join('-');
    return dataFormatada;
  };


  //Handle de submit do form
  const handleFormSubmit = async (values) => {
    const url = `${api}/motivacaoenergia/cadastro`;
    const myDate = handleDataHora(new Date(Date.now()).toLocaleString().split(',')[0].trim());
    const myHour = new Date(Date.now()).toLocaleString().split(',')[1].trim();

    if(dataEnergia && dataMotivacao) {
      for(let i = 0; i < 2; i++) {
        if(i===0) {
          const answer = {
            item : t("Energy"),
            feeling : "",
            score : parseFloat(dataEnergia),
            classification : t("Energy"),
            answer_type : "energymotivation",
            realization_date : myDate,
            realization_time : myHour,
            campaign_id : parseInt(campaign_id),
            user_id : parseInt(user_id)
          };
        
          const request = await fetch(url,{
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Methods": "POST, GET, PUT, DELETE",
              "Access-Control-Allow-Headers": "*",
              "Access-Control-Max-Age": "86400",
            },
            body: JSON.stringify(answer),
          });
          setResp(request.status);
          if(request.status >= 200 && request.status <= 299){
            navigate(`/formHappinessradar/${user_id}/${campaign_id}`);
          }
        }
        else {
          const answer = {
            item : t("Motivation"),
            feeling : "",
            score : parseFloat(dataMotivacao),
            classification : t("Motivation"),
            answer_type : "energymotivation",
            realization_date : myDate,
            realization_time : myHour,
            campaign_id : parseInt(campaign_id),
            user_id : parseInt(user_id)
          };
          
          const request = await fetch(url,{
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Methods": "POST, GET, PUT, DELETE",
              "Access-Control-Allow-Headers": "*",
              "Access-Control-Max-Age": "86400",
            },
            body: JSON.stringify(answer),
          });
          setResp(request.status);
          if(request.status >= 200 && request.status <= 299){
            navigate(`/formHappinessradar/${user_id}/${campaign_id}`);
          }
        }
      }
    }
    else if(dataEnergia && !dataMotivacao){
      const answer = {
        item : t("Energy"),
        feeling : "",
        score : parseFloat(dataEnergia),
        classification : t("Energy"),
        answer_type : "energymotivation",
        realization_date : myDate,
        realization_time : myHour,
        campaign_id : parseInt(campaign_id),
        user_id : parseInt(user_id)
      };
    
      const request = await fetch(url,{
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Methods": "POST, GET, PUT, DELETE",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Max-Age": "86400",
        },
        body: JSON.stringify(answer),
      });
      setResp(request.status);
      if(request.status >= 200 && request.status <= 299){
        navigate(`/formHappinessradar/${user_id}/${campaign_id}`);
      }
    }
    else if(!dataEnergia && dataMotivacao){
      const answer = {
        item : t("Motivation"),
        feeling : "",
        score : parseFloat(dataMotivacao),
        classification : t("Motivation"),
        answer_type : "energymotivation",
        realization_date : myDate,
        realization_time : myHour,
        campaign_id : parseInt(campaign_id),
        user_id : parseInt(user_id)
      };
      
      const request = await fetch(url,{
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Methods": "POST, GET, PUT, DELETE",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Max-Age": "86400",
        },
        body: JSON.stringify(answer),
      });
      setResp(request.status);
      if(request.status >= 200 && request.status <= 299){
        navigate(`/formHappinessradar/${user_id}/${campaign_id}`);
      }
    }
  };
  //------------------------------------------------

  return (
    <Box m="20px">
      <Header title={t("ENERGY & MOTIVATION")} subtitle={t("Enter your energy level and motivation")} />

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        //validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit} >
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <CustomizedRating
                gridColumn="span 2"
                defaultValue={0}
                precision={0.5}
                size="large"
                title={t("What is your level of motivation?")}
                name="Motivation"
                childToParent={childToParentMotivacao}
              />
              <CustomizedRating
                gridColumn="span 2"
                defaultValue={0}
                precision={0.5}
                size="large"
                title={t("What is your energy level?")}
                name="Energy"
                childToParent={childToParentEnergia}
              />
            </Box>
            <SnackBars buttonName={t("SAVE ENERGY MOTIVATION")} buttonType="submit" resp={resp} />
          </form>
        )}
      </Formik>
    </Box>
  );
};

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const checkoutSchema = yup.object().shape({
  safety_check: yup.string().required("required"),
});
const initialValues = {
  safety_check: "",
};

export default FormEnergyMotivation;