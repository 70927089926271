import { Box, RadioGroup, Radio, Typography, useTheme } from "@mui/material";
import { tokens } from "../theme";
import { Formik ,Field } from "formik";
import ProgressCircle from "./ProgressCircle";
import '../utils/i18n';
import { useTranslation } from "react-i18next";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TextEditor from "./TextEditor";

const HeaderActionBox = ({pdi_type, objective, user, anotation}) => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  initialValues.anotation = anotation;

  return (
    <Box
      backgroundColor={colors.primary[400]}
      flex="grid"
      gridColumn="span 12"
      borderRadius="10px"
    >
      <Accordion sx={{backgroundColor: "transparent"}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Box width="33%">
            <Typography
              variant="h5"
              //fontWeight="bold"
              sx={{ color: colors.greenAccent[500], padding: "0", cursor: "pointer", textAlign: "left" }}
            >
              PDI:
              <Typography
                  variant="h5"
                  //fontWeight="bold"
                  sx={{ color: colors.grey[100], padding: "0", cursor: "pointer", textAlign: "left" }}
                >
                  {pdi_type}
                </Typography>
            </Typography>
          </Box>
          <Box width="33%">
          <Typography
              variant="h5"
              //fontWeight="bold"
              sx={{ color: colors.greenAccent[500], padding: "0", cursor: "pointer", textAlign: "left" }}
            >
              Objetivo:
              <Typography
                  variant="h5"
                  //fontWeight="bold"
                  sx={{ color: colors.grey[100], padding: "0", cursor: "pointer", textAlign: "left" }}
                >
                  {objective}
                </Typography>
            </Typography>
          </Box>
          <Box width="33%">
          <Typography
              variant="h5"
              //fontWeight="bold"
              sx={{ color: colors.greenAccent[500], padding: "0", cursor: "pointer", textAlign: "left" }}
            >
              Colaborador:
              <Typography
                  variant="h5"
                  //fontWeight="bold"
                  sx={{ color: colors.grey[100], padding: "0", cursor: "pointer", textAlign: "left" }}
                >
                  {user}
                </Typography>
            </Typography>
          </Box>
              </AccordionSummary>
                <AccordionDetails>
                  <Formik
                    initialValues={initialValues}
                  >
                    <Field
                      name="anotation"
                      component={TextEditor}
                      sx={{ gridColumn: "span 12" }}
                      minLines={4}
                      maxLines={6}
                      readOnly={true}
                    />
                  </Formik>
                  
                </AccordionDetails>
            </Accordion>
    </Box>
  );
};

const initialValues = {
  anotation: "",
};

export default HeaderActionBox;
