import { Box, TextField,Select,FormControl,InputLabel,MenuItem, useTheme } from "@mui/material";
import { tokens } from "../../../theme";
import { Formik, Field } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../../components/Header";
import SnackBars from "../../../components/CustomizedSnackBars";
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {useFetch} from '../../../hooks/useFetch';
import TeamProfile from "../../../hooks/useTeam";
import LoginProfile from "../../../hooks/useLoginUser";
import '../../../utils/i18n';
import { useTranslation } from "react-i18next";
import InputMask from "react-input-mask";
import TextEditor from "../../../components/TextEditor";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import FAB from "../../../components/FAB";
import EditIcon from "@mui/icons-material/Edit";
import Delete from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";


const FormInsertActivity = () => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [resp, setResp] = useState(null);
  const [employer, setEmployer] = useState([]);
  const [dados, setDados] = useState([]);
  const [data, setData] = useState([]);
  const [value, setValue] = useState(null);

  
  const[list, setList] = useState([]);
  const [activity, setActivity] = useState([]);


  const handleActivity = (values) => {
    const newItem = { id: activity.length + 1, activity: values.activity, status: 'plan', goal: values.goal, actionpla_id: null }; // Defina o novo item
    setActivity([...activity, newItem]); // Atualize o estado com o novo item
    values.activity = "";
    values.goal = "";
  };

  const columns = [
    {field: "id", headerName: "ID",flex:0.2},
    {
      field: "activity",
      headerName: t("Activity"),
      flex: 1,
    },
    {
      field: "status",
      headerName: t("Status"),
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "goal",
      headerName: t("Goal"),
      flex: 2,
    },
  ];

  const api = process.env.REACT_APP_API_URL;

  const { pdi_id, id, call_action } = useParams();

  const navigate = useNavigate();


  const fetchEmployer = async () => {
    const url = `${api}/user/team/${LoginProfile.getUserId()}`;
    const options = {
      method: 'GET',
      headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Methods": "POST, GET, PUT, DELETE",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Max-Age": "86400",
          "Authorization": `${sessionStorage.getItem('token')}`
        }
    };
    const resp = await fetch(url, options);
    const json = await resp.json();
    setEmployer(json);
  };

  const fetchPdi = async () => {
    const url = `${api}/pdi/${pdi_id}`;
    const options = {
      method: 'GET',
      headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Methods": "POST, GET, PUT, DELETE",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Max-Age": "86400",
          "Authorization": `${sessionStorage.getItem('token')}`
        }
    };
    const resp = await fetch(url, options);
    const json = await resp.json(resp);  
    setDados(json);
  };

  const fetchActionPlan = async () => {
    const url = `${api}/actionplan/${id}`;
    const options = {
      method: 'GET',
      headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Methods": "POST, GET, PUT, DELETE",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Max-Age": "86400",
          "Authorization": `${sessionStorage.getItem('token')}`
        }
    };
    const resp = await fetch(url, options);
    const json = await resp.json(resp);  
    setData(json);
  };


  useEffect(()=> {
    fetchEmployer();
    if(call_action === 'add'){
      fetchPdi();
    }
    else if(call_action === 'edit'){
      fetchPdi();
      fetchActionPlan();
    }  
      },[]);


  initialValues.employer = `${dados.user_id ? dados.user_id : ""}`;
  initialValues.type = `${data.actionplan_type ? data.actionplan_type : ""}`;
  initialValues.description = `${data.description ? data.description : ""}`;
  initialValues.action = `${data.action ? data.action : ""}`;
  initialValues.resources = `${data.resources ? data.resources : ""}`;
  initialValues.initial_date = `${data.initial_date ? data.initial_date : ""}`;
  initialValues.end_date = `${data.end_date ? data.end_date : ""}`;
  initialValues.status = `${data.status ? data.status : ""}`;


  //seta o resp como null sempre que o form for alterado
  const handleFormChange = () => {
    setResp(null);
  };

  //--------------------------------------------------

  const handleDelete = async (values) => {
    if(list.length > 1) {
      list.forEach(async id => {
        const arrayActivity = activity.filter((item) => !list.includes(item.id));
        setActivity(arrayActivity);
      });
    }
    else {
      const arrayActivity = activity.filter((item) => !list.includes(item.id));
      setActivity(arrayActivity);
    }
  };

  const handleDataHora = (dataHora) => {
    let dataFormatada = new Date();
    dataFormatada = dataHora.split('/').reverse().join('-');
    return dataFormatada;
  };

  //Handle de submit do form
  const handleFormSubmit = async (values) => {
    const url = `${api}/actionplan/cadastro`;

    const actionplan = {
      actionplan_id : id !== 'null' ? id : undefined,
      description : values.description,
      action : values.action,
      resources : values.resources,
      actionplan_type : values.type,
      initial_date : handleDataHora(values.initial_date),
      end_date : handleDataHora(values.end_date),
      user_id : values.employer,
      pdi_id: pdi_id,
      status: values.status,
      previous_status: undefined,
      approved: undefined
    };

    const request = await fetch(url,{
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "Access-Control-Allow-Methods": "POST, GET, PUT, DELETE",
        "Access-Control-Allow-Headers": "*",
        "Access-Control-Max-Age": "86400",
        "Authorization": `${sessionStorage.getItem('token')}`
      },
      body: JSON.stringify(actionplan),
    });
    const { actionplan_id } = await request.json();
    setResp(request.status);
  };

  const maskDate = () => {
    switch(localStorage.getItem('language')){
      case "br":
        return '99/99/9999';
      case "es":
        return '99-99-9999';
      case "us":
        return '99-99-9999';
    }
    return 0;
  };

  return (
    <Box m="20px">
      <Header title={t("Action Plan")} subtitle={t("Create you action plan")} />

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit} onChange={handleFormChange}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(12, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 12" },
              }}
            >
              <FormControl fullWidth sx={{ gridColumn: "span 6" }}>
                <InputLabel>{t("Employee")}</InputLabel>
                <Select
                  fullWidth
                  variant="filled"
                  label={t("Employer")}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.employer}
                  name="employer"
                  error={!!touched.employer && !!errors.employer}
                  helperText={touched.employer && errors.employer}
                  sx={{ gridColumn: "span 6" }}
                  >
                    {employer && employer.map((employ)=>(
                      <MenuItem value={employ.id}>{t(employ.name)}</MenuItem>  
                    ))}
                </Select>
              </FormControl>
              <FormControl fullWidth sx={{ gridColumn: "span 6" }}>
                <InputLabel>{t("Type")}</InputLabel>
                <Select
                  fullWidth
                  variant="filled"
                  label={t("Type")}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.type}
                  name="type"
                  error={!!touched.type && !!errors.type}
                  helperText={touched.type && errors.type}
                  sx={{ gridColumn: "span 6" }}
                  >
                    <MenuItem value={t("Professional challenge")}>{t("Professional challenge")}</MenuItem>
                    <MenuItem value={t("Training and Study")}>{t("Training and Study")}</MenuItem>
                    <MenuItem value={t("Mentoring and Feedback")}>{t("Mentoring and Feedback")}</MenuItem>
                </Select>
              </FormControl>
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label={t("Description")}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.description}
                name="description"
                error={!!touched.description && !!errors.description}
                helperText={touched.description && errors.description}
                sx={{ gridColumn: "span 12" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label={t("Action")}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.action}
                name="action"
                error={!!touched.action && !!errors.action}
                helperText={touched.action && errors.action}
                sx={{ gridColumn: "span 12" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label={t("Resources")}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.resources}
                name="resources"
                error={!!touched.resources && !!errors.resources}
                helperText={touched.resources && errors.resources}
                sx={{ gridColumn: "span 12" }}
              />
              <InputMask
                mask={maskDate()}
                maskPlaceholder={null} // Isso remove o hint de máscara do campo
                onChange={handleChange}
                value={values.initial_date}
                name="initial_date"
                error={!!(touched.initial_date && errors.initial_date)} // Considere o email como erro se houver um erro retornado pela API
                helperText={touched.initial_date && errors.initial_date} 
                sx={{ gridColumn: "span 4" }}
              >
                {(inputProps) => <TextField {...inputProps} fullWidth variant="filled" type="text" label={t("Start Date")} />}
              </InputMask>
              <InputMask
                mask={maskDate()}
                maskPlaceholder={null} // Isso remove o hint de máscara do campo
                onChange={handleChange}
                value={values.end_date}
                name="end_date"
                error={!!(touched.end_date && errors.end_date)} // Considere o email como erro se houver um erro retornado pela API
                helperText={touched.end_date && errors.end_date} 
                sx={{ gridColumn: "span 4" }}
              >
                {(inputProps) => <TextField {...inputProps} fullWidth variant="filled" type="text" label={t("End Date")} />}
              </InputMask>
              <FormControl fullWidth sx={{ gridColumn: "span 4" }}>
                <InputLabel>{t("Status")}</InputLabel>
                <Select
                  fullWidth
                  variant="filled"
                  label={t("Status")}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.status}
                  name="status"
                  error={!!touched.status && !!errors.status}
                  helperText={touched.status && errors.status}
                  sx={{ gridColumn: "span 4" }}
                  >
                    <MenuItem value={t("To Do")}>{t("To Do")}</MenuItem>
                    <MenuItem value={t("Doing")}>{t("Doing")}</MenuItem>
                    <MenuItem value={t("Delayed")}>{t("Delayed")}</MenuItem>
                    <MenuItem value={t("Done")}>{t("Done")}</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <SnackBars buttonName={t("Save Action Plan")} buttonType="submit" resp={resp} />
          </form>
        )}
      </Formik>
    </Box>
  );
};

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const checkoutSchema = yup.object().shape({
  employer: yup.string().required("required")
});
const initialValues = {
  employer: "",
  objective: "",
  anotation: "",
};

export default FormInsertActivity;