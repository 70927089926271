import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Estilo do editor

const modules = {
    toolbar: [
      [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
      [{size: []}],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
      ['link', 'image', 'video'],
      ['clean']                                         
    ],
  };

const TextEditor = ({ field, form, sx, minLines, maxLines, readOnly, ...props }) => {

  const handleChange = (value) => {
    form.setFieldValue(field.name, value);
  };

  const minHeight = `${minLines * 24}px`; // Aproximadamente 20px por linha
  const maxHeight = `${maxLines * 24}px`; // Aproximadamente 20px por linha

  return (
    <div style={sx} >
      <ReactQuill
        readOnly={readOnly}
        value={field.value}
        onChange={handleChange}
        modules={modules}
        style={{minHeight}}
        {...props}
       />
      <style jsx>{`
        .ql-container {
          min-height: ${minHeight};
          max-height: ${maxHeight};
        }
        .ql-editor {
          min-height: ${minHeight};
          max-height: ${maxHeight};
        }
      `}</style>
    </div>
  );
};

export default TextEditor;