import React, { useContext } from 'react'
import { Context } from "./Auth"
import { Navigate } from 'react-router-dom';

export function CustomRoute({ children }){
  const { authenticated } = useContext(Context);

  if(!authenticated) {
    return <Navigate to="/login" />
  }
  else{

   return children;

  }
}