import { Box, useTheme } from "@mui/material";
import { Formik } from "formik";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import {useEffect, useState} from 'react';
import { useParams, useNavigate } from "react-router-dom";
import FAB from "../../components/FAB";
import EditIcon from "@mui/icons-material/Edit";
import Delete from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import LoginProfile from "../../hooks/useLoginUser";
import TeamProfile from "../../hooks/useTeam";
import { useLocation } from 'react-router-dom';
import '../../utils/i18n';
import { useTranslation } from "react-i18next";
import ChallengeBox from '../../components/ChallengeBox';
import AccordionActionPlan from '../../components/AccordionActionPlan';
import Chip from '@mui/material/Chip';
import PercentOutlinedIcon from '@mui/icons-material/PercentOutlined';
import StatAction from "../../components/StatAction";
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import DoneAllOutlinedIcon from '@mui/icons-material/DoneAllOutlined';
import SpellcheckOutlinedIcon from '@mui/icons-material/SpellcheckOutlined';

const mockRows = [{
  "id": 1,
  "teamName": undefined,
  "campaign_name": undefined,
  "people_target": undefined,
  "initial_date": undefined,
  "end_date": undefined,
  "objective": undefined
}];

const ActionPlan = () => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [className, setClassName] = useState();

  const api = process.env.REACT_APP_API_URL;

  const location = useLocation();

  const navigate = useNavigate();

  //recebe id do account para carregar o form para edição
  const { id } = useParams();
  //----------------------------------------------------
  
  const [list, setList] = useState([]);
  const [actionplan, setActionPlan] = useState([]);
  const [pdi, setPdi] = useState([]);
  const [bignumber, setBignumber] = useState([]);
  const [planned, setPlanned] = useState(0);
  const [doing, setDoing] = useState(0);
  const [done, setDone] = useState(0);
  const [selectedBox, setSelectedBox] = useState(null); // Estado para armazenar o id do componente selecionado

  let row = [];

  const handleActionPlan = (id) => {
    actionplan.map(function(item){
      if(item.id === id){
        //setRow(item);
        row = item;
      }
      return item;
    });
  };

  const handleDataHora = (dataHora) => {
    let dataFormatada = new Date();
    dataFormatada = dataHora.split('/').reverse().join('-');
    return dataFormatada;
  };


  const fetchData = async () => {
    const url = `${api}/actionplan/pdi/${id}`;
    const options = {
      method: 'GET',
      headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Methods": "POST, GET, PUT, DELETE",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Max-Age": "86400",
          "Authorization": `${sessionStorage.getItem('token')}`
        }
    };
    const resp = await fetch(url, options);
    const json = await resp.json(resp);
    if(resp.ok){
      setActionPlan(json);
      console.log('actionplan',json);
    }
    else{
      //navigate(`/pdi/activity/${id}`)
    }
  };

  const fetchPDI = async () => {
    const url = `${api}/pdi/${id}`;
    const options = {
      method: 'GET',
      headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Methods": "POST, GET, PUT, DELETE",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Max-Age": "86400",
          "Authorization": `${sessionStorage.getItem('token')}`
        }
    };
    const resp = await fetch(url, options);
    const json = await resp.json(resp);
    if(resp.ok){
      setPdi(json);
    }
    else{
      //navigate(`/pdi/activity/${id}`)
    }
  };

  const fetchBigNumber = async () => {
    const url = `${api}/actionplan/bignumber/${id}`;
    const options = {
      method: 'GET',
      headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Methods": "POST, GET, PUT, DELETE",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Max-Age": "86400",
          "Authorization": `${sessionStorage.getItem('token')}`
        }
    };
    const resp = await fetch(url, options);
    const json = await resp.json(resp);
    if(resp.ok){
      const { planned, doing ,done } = json[0];
      setPlanned(planned);
      setDoing(doing);
      setDone(done);
      setBignumber(json);
    }
    else{
      //navigate(`/pdi/activity/${id}`)
    }
  };

  useEffect(() => {
    fetchData();
    fetchPDI();
    fetchBigNumber();
  }, [localStorage.getItem('alter_teams')]);

  
  const handleDelete = async () => {
    const url = `${api}/actionplan/${selectedBox}`;
      const request = await fetch(url, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Methods": "POST, GET, PUT, DELETE",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Max-Age": "86400",
          "Authorization": `${sessionStorage.getItem('token')}`
        },
      });
      fetchData();
      fetchBigNumber();
      setActionPlan(null);
  };

  const handleConcluir = async (id) => {
    console.log('concluir_id',id)
    const url = `${api}/actionplan/status/update`;
    let status = list;
    let act_status;

    actionplan.map((itens) => {
      if(itens.actionplan_id === id){
        act_status = itens.status;
      }
    })

    switch(act_status){
      case "To Do":
        status = {
          previous_status: "To Do",
          status: "Doing",
          actionplan_id: id
        };
        break;
      case "Doing":
        status = {
          previous_status: "Doing",
          status: "Done",
          actionplan_id: id
        };
        break;
      case "Delayed":
        status = {
          previous_status: "Delayed",
          status: "Done",
          actionplan_id: id
        };
        break;
    }

    console.log("status", status);

    const request = await fetch(url,{
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "Access-Control-Allow-Methods": "POST, GET, PUT, DELETE",
        "Access-Control-Allow-Headers": "*",
        "Access-Control-Max-Age": "86400",
        "Authorization": `${sessionStorage.getItem('token')}`
      },
      body: JSON.stringify(status),
    });
    if(request.ok){
      fetchData();
      fetchBigNumber();
    }
    else{
      console.log(request.json());
    }
  };
 
  const handleBoxSelect = (id) => {
    setSelectedBox(id);
  }

  const handleStatus = (initial_date, end_date) => {
    const today = new Date(Date.now());
    today.setHours(0,0,0,0);
    
    const start = new Date(initial_date);
    start.setHours(0,0,0,0);
    
    const end = new Date(end_date);
    end.setHours(0,0,0,0);
    
    if(start > today){
      return "To Do";
    }
    else if(start <= today && end >= today){
      return "Doing"
    }
    else if(end < today){
      return "Done";
    }
  }

  const handleStatusColor = (status) => {
    switch(status){
      case 'To Do':
        return 'default';
      case 'Doing':
        return 'info';
      case 'Done':
        return 'success';
      case 'Delayed':
        return 'warning';
    }
  }

  const calcMilissegundosEmDias = (data) => {
    let dias = data / (1000 * 60 * 60 * 24);
    return dias;
  }

  const handlePercent = (planejado, realizado) => {
    if(realizado === 0){
      return 0;
    }
    else{
      try{
        let result = ((realizado / planejado) * 100);
        return result.toFixed(0);
      }
      catch(error){
        console.log(error);
        return error;
      }
    }
  }

  const handleLanguageDate = () => {
    switch(localStorage.getItem('language')){
      case 'br':
        return 'pt-BR';
      case 'us':
        return 'en-US';
      case 'es':
        return 'es-ES';
    }
  }
  
  return (
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title={t("Action Plan")} subtitle={t("Create and manage your team’s action plans")} />
      </Box>

      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        //gridAutoRows="140px"
        gap="20px"
        m="5px 20px"
      >
        <AccordionActionPlan
          pdi_type={pdi.type}
          objective={pdi.objective}
          user={pdi.user_name}
          anotation={pdi.anotation}
        />
      </Box>
      {/* GRID & CHARTS */}
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="110px"
        gap="20px"
        m="20px"
      >
        {/* ROW 1 */}
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
          borderRadius="10px"
        >
          <StatAction
            title={planned}
            subtitle={t("Planned")}
            progress={20 / 100}
            increase={20 + "%"}
            icon={
              <DriveFileRenameOutlineOutlinedIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
          borderRadius="10px"
        >
          <StatAction
            title={doing}
            subtitle={t("Doing")}
            progress={20 / 100}
            increase={20 + "%"}
            icon={
              <DoneAllOutlinedIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
          borderRadius="10px"
        >
          <StatAction
            title={done}
            subtitle={t("Accomplished")}
            progress={20 / 100}
            increase={20 + "%"}
            icon={
              <DoneAllOutlinedIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        {/**
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
          borderRadius="10px"
        >
          <StatAction
            title={"1"}
            subtitle={t("Aprovado")}
            progress={20 / 100}
            increase={20 + "%"}
            icon={
              <SpellcheckOutlinedIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        */}
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
          borderRadius="10px"
        >
          <StatAction
            title={handlePercent(planned, done)}
            subtitle={t("Done")}
            progress={35 / 100}
            increase={35 + "%"}
            icon={
              <PercentOutlinedIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
      </Box>
      <Box>
        <Box  display="flex" mr="10px" justifyContent="end" gap={1}>
          {/*
          <FAB
           to={`/feedback/assessment/${list[0]}`}
           name="touch"
           title={t("Performance")}
           icon={<RecordVoiceOverIcon />}
           disabled={list.length >= 1 ? false : true}
           id={list}
           handleSendTouch={handleSendTouch}
          />
          */}
          <FAB
            to={`/pdi/activity/${id}/actionplan/${selectedBox}/call_action/add`}
            title={t("Add new action plan")}
            icon={<AddIcon />}
          />
          <FAB
            to={`/pdi/activity/${id}/actionplan/${selectedBox}/call_action/edit`}
            icon={<EditIcon />}
            title={t("Edit action plan")}
            disabled={selectedBox >= 1 ? false : true}
          />
          <FAB
            to={`/pdi/actionplan/${id}`}
            icon={<Delete />}
            disabled={selectedBox >= 1 ? false : true}
            id={selectedBox}
            handleDelete={handleDelete}
            name="delete"
            title={t("Delete feedback")}
          />
        </Box>
          {
            actionplan ? actionplan.map((itens, index) => (
              <ChallengeBox
                id={itens.actionplan_id}
                title={t(itens.description)}
                subtitle={t(itens.type)}
                //progress={handlePercent(itens.initial_date, itens.end_date, handleStatus(itens.initial_date, itens.end_date)) / 100}
                //increase={handlePercent(itens.initial_date, itens.end_date, handleStatus(itens.initial_date, itens.end_date)) + "%"}
                icon={
                [<Chip label={itens.status} color={handleStatusColor(itens.status)} />,
                  " ",
                  itens.delayed ? <Chip label={"Delayed"} color={handleStatusColor("Delayed")} /> : null]
                }
                selected={selectedBox === itens.actionplan_id}
                onSelected={handleBoxSelect}
                clickStatus={handleConcluir}
                action={itens.action}
                initial_date={new Date(itens.initial_date).toLocaleString(handleLanguageDate()).split(',')[0].trim()}
                end_date={new Date(itens.end_date).toLocaleString(handleLanguageDate()).split(',')[0].trim()}
              />
            )) : null
          }
      </Box>
    </Box>
  );
};

export default ActionPlan;
