import { Button, Box, TextField, Container, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import RecommendOutlinedIcon from '@mui/icons-material/RecommendOutlined';
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { useContext, useState } from "react";
import TeamProfile from "../../hooks/useTeam";
import LoginSnackBar from "../../components/LoginSnackBars";
import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from "jwt-decode";
import { tokens, useMode } from "../../theme";
import LoginProfile from "../../hooks/useLoginUser";
import { Context } from "../../components/Auth";
import { Formik } from "formik";
import useMediaQuery from "@mui/material/useMediaQuery";
import * as yup from "yup";


const LoginPage = () => {
  const [theme, colorMode] = useMode();
  const colors = tokens(theme.palette.mode);
  //const [resp, setResp] = useState(null);
  const isNonMobile = useMediaQuery("(min-width:600px)");

  let resp = null;

  const { authenticated, handleLogin } = useContext(Context);

  const navigate = useNavigate();
  const api = process.env.REACT_APP_API_URL;

  initialValues.fullName = "";

  const isDark = () => {
    if(theme.palette.mode === "dark"){
      return "secondary";
    }
    else {
      return "primary";
    }
  };

  //Handle de submit do form
  const handleFormSubmit = async (values) => {
    //seta url para cadastro (POST) e grava o retorno da API no Resp
    const url = `${api}/login/auth`;

    const user = {
      email : values.email,
      password : values.password,
    };
    const request = await fetch(url,{
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "Access-Control-Allow-Methods": "POST, GET, PUT, DELETE",
        "Access-Control-Allow-Headers": "Content-Type, Authorization",
        "Access-Control-Max-Age": "86400",
        "Acceped": "*/*",
      },
      body: JSON.stringify(user),
    });
    if (request.ok) {
      if(parseInt(request.status) === 200){
        resp = parseInt(request.status);
        const { token } = await request.json();
        const { sub } = jwtDecode(token);
        const { account, teams, login } = sub;
        handleLoginProfile(account[0][0].account_id, account[0][0].fullName, values.email, account[0][0].product_id,
          account[0][0].product_duration, teams[0].id, account[0][0].account_uuid, login[0].id, login[0].user_id);
        sessionStorage.setItem('token',`Bearer ${token}`);
        handleLogin();
      }      
    } else {
      resp = parseInt(request.status);
    }
  };

  const handleLoginProfile = (accountId, fullName, email, productId, productDuration, team_id, account_uuid, login_id, user_id ) => {
    LoginProfile.setAccountId(accountId);
    LoginProfile.setFullName(fullName);
    LoginProfile.setEmail(email);
    LoginProfile.setProductId(productId);
    LoginProfile.setProductDuration(productDuration);
    TeamProfile.setTeamID(team_id);
    LoginProfile.setAccountUUID(account_uuid);
    LoginProfile.setLoginId(login_id);
    LoginProfile.setUserId(user_id)
  };

  //seta o resp como null sempre que o form for alterado
  const handleFormChange = () => {
    //setResp(null);
    resp = null;
  };

  return (
    <Container component="main">
      <Box
          style={{
            display: "flex",
            flexDirection:"row",
            alignContent: "center",
            justifyContent: "center",
            gap:"10px",
            padding:"10px",
            marginTop: theme.spacing(8),
            //backgroundColor: colors.grey[400],
          }}
      >
        
        {/*BOX 1 */}
        <Box
          style={{
            width: "50%",
            display: "grid",
            alignItems: "center",
            //backgroundColor: colors.primary[400],
            padding: 30,
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
                <img
                alt="profile-user"
                width="100%"
                height="100%"
                src={`/assets/login_allmetrics.png`}
                //style={{ cursor: "pointer", borderRadius: "50%" }}
                />
          </Box>
          
        </Box>
        {/*BOX 2 */}
        <Box
          style={{
            width: "50%",
            display: "grid",
            alignItems: "flex-start",
            borderLeft: "solid 1px " + colors.greenAccent[500],
            borderColor: colors.greenAccent[500],
            padding: 20,
          }}
        >
          <Typography component="h1" variant="h5" style={{ color: colors.greenAccent[500] }}>
            Sign in
          </Typography>
          <Box
          >
            <Formik
              onSubmit={handleFormSubmit}
              initialValues={initialValues}
              validationSchema={checkoutSchema}
            >
              {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
              }) => (
                <form onSubmit={handleSubmit} onChange={handleFormChange}>
                  <Box
                    display="grid"
                    gap="30px"
                    gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                    sx={{
                      "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                    }}
                  >
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="E-mail"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.email}
                      name="email"
                      error={!!touched.email && !!errors.email}
                      helperText={touched.email && errors.email}
                      sx={{ gridColumn: "span 4" }}
                    />
                    <TextField
                      fullWidth
                      variant="filled"
                      type="password"
                      label="Senha"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.password}
                      name="password"
                      error={!!touched.password && !!errors.password}
                      helperText={touched.password && errors.password}
                      sx={{ gridColumn: "span 4" }}
                    />
                    <Box sx={{ gridColumn: "span 4"}}>
                    <Link to="./recovery" style={{ textDecoration: "none", color: colors.greenAccent[500] }}> I forgot my password</Link>
                    </Box>
                  </Box>
                  <LoginSnackBar buttonName="Sign In" buttonType="submit" resp={resp} />
                </form>
              )}
            </Formik>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

const passwordRegExp = /^(?=.*[A-Z])(?=.*[!@#$%^&*()-+=])(?=.{8,})/;

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const checkoutSchema = yup.object().shape({
  email: yup.string().email("invalid email").required("required"),
  password: yup
    .string()
    .required("required")
});
const initialValues = {
  fullName: "",
  email: "",
  phone: "",
  position: "",
  people_target: ""
};

export default LoginPage;
