import { Box, TextField, Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../../components/Header";
import { mockPaises } from '../../../data/mockPaises copy'
import SnackBars from "../../../components/CustomizedSnackBars";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import LoginProfile from "../../../hooks/useLoginUser";
import '../../../utils/i18n';
import { useTranslation } from "react-i18next";
import TeamProfile from "../../../hooks/useTeam";

const FormInvite = () => {
  const { t, i18n } = useTranslation();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [method,setMethod] = useState(null);
  const [profile, setProfile] = useState([]);
  const [team, setTeam] = useState([]);

  //recebe id do account para carregar o form para edição
  
  const { id } = useParams();
  const api = process.env.REACT_APP_API_URL;
  //----------------------------------------------------

  //seta url para cadastro (POST) e grava o retorno da API no Resp
  const [resp, setResp] = useState(null);
  //-------------------------------------------------------------

  //seta o resp como null sempre que o form for alterado
  const handleFormChange = () => {
    setResp(null);
  };
  //--------------------------------------------------

  const fetchProfile = async () => {
    const url = `${api}/profile/allprofile`;
    const options = {
      method: 'GET',
      headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Methods": "POST, GET, PUT, DELETE",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Max-Age": "86400",
          "Authorization": `${sessionStorage.getItem('token')}`
        }
    };
    const resp = await fetch(url, options);
    const json = await resp.json();
    setProfile(json);
    console.log('profile', json);
  };

  useEffect(() => {
    fetchProfile();
  }, []);

  //Handle de submit do form
  const handleFormSubmit = async (values) => {
    const accountUrl = `${api}/login/invite`;
    
    const invite = {
      account_uuid : LoginProfile.getAccountUUID(),
      account_id : LoginProfile.getAccountId(),
      profile_id : values.profile,
      email : values.email,
    };

    //console.log(invite);
    
    const reqAccount = await fetch(accountUrl,{
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "Access-Control-Allow-Methods": "POST, GET, PUT, DELETE",
        "Access-Control-Allow-Headers": "*",
        "Access-Control-Max-Age": "86400",
      },
      body: JSON.stringify(invite),
    });
    setResp(reqAccount.status);
  };
  //------------------------------------------------

  return (
    <Box m="20px">
      <Header title={t("Invite Administrative Users")} subtitle={t("Invite new administrative users")} />

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit} onChange={handleFormChange}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Email"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.email}
                name="email"
                error={!!touched.email && !!errors.email}
                helperText={touched.email && errors.email}
                sx={{ gridColumn: "span 4" }}
              />
              <FormControl fullWidth sx={{ gridColumn: "span 4" }}>
                <InputLabel>{t("Profile")}</InputLabel>
                <Select
                  fullWidth
                  variant="filled"
                  label={t("Profile")}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.profile}
                  name="profile"
                  error={!!touched.profile && !!errors.profile}
                  helperText={touched.profile && errors.profile}
                  sx={{ gridColumn: "span 4" }}
                  >
                    {profile && profile.map((itens) => (
                      <MenuItem value={t(itens.id)}>{t(itens.profile_name)}</MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Box>
            <SnackBars buttonName={t("SEND INVITE")} buttonType="submit" resp={resp} />
          </form>
        )}
      </Formik>
    </Box>
  );
};

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const checkoutSchema = yup.object().shape({
  email: yup.string().email("invalid email").required("required")
});
const initialValues = {
  email: ""
};

export default FormInvite;